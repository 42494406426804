import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import Avatar from "./Avatar";

const CLUE_SWAP_SPEED = 12000;

const CrosswordWrapper = styled.div`
  pointer-events: none;
  .guessed {
    text-decoration: line-through;
    color: #aaa;
  }
`;

const TeamEmoji = (props) => {
  const { team, style } = props;
  return (
    <div style={{ lineHeight: 1, ...style }}>
      {team === "cats" ? "🐱" : ""}
      {team === "dogs" ? "🐶" : ""}
    </div>
  );
};

const QuizWrapper = styled.div`
  margin: 0 20px;
  .clue {
    color: #666;
    margin-left: 6px;
  }
  .answers {
    column-width: 300px;
  }
  .answers > div {
    position: relative;
    padding-top: 16px;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }
  .answer {
    font-weight: bold;
    font-size: 20px;
  }
  .placeholder {
    font-size: 20px;
    color: #666;
  }
  .clue {
    font-weight: normal;
  }
  .space {
    display: inline-block;
    width: 10px;
    height: 20px;
    margin-right: 2px;
  }
  .word {
    display: inline;
    white-space: nowrap;
    margin-right: 12px;
  }
  .blank {
    display: inline-block;
    width: 16px;
    height: 20px;
    border-bottom: 2px solid #666;
    margin-right: 2px;
  }
  .revealed {
    color: #cc0000;
  }
`;

const QuizPuzzle = (props) => {
  const {
    puzzleData,
    guesses,
    allGuesses,
    userGuesses,
    gameMode,
    playerLists,
    roundActive,
  } = props;
  console.log("props", props);
  const puzzEl = useRef(null);
  const [puzzleLoaded, setPuzzleLoaded] = useState(false);
  const [crosswordCorrect, setCrosswordCorrect] = useState(false);
  const [showClues, setShowClues] = useState("across");
  const [scoreboard, setScoreboard] = useState({ players: {}, teams: {} });

  console.log("moo", gameMode, playerLists, scoreboard, guesses);

  //   useEffect(() => {
  //     const intervalId = setInterval(() => {
  //       setShowClues(showClues === "across" ? "down" : "across");
  //     }, CLUE_SWAP_SPEED);

  //     return () => {
  //       clearInterval(intervalId);
  //     };
  //   }, [setShowClues, showClues]);

  const calculateScores = useCallback(() => {
    // // puzzEl.current.reset();
    // // puzzEl.current.setGuess(0, 0, "A");
    // guesses.forEach((guess, i) => {
    //   if (guess.dir === "across") {
    //     for (let i = 0; i < guess.answer.length; i++) {
    //       puzzEl.current.setGuess(
    //         guess.row,
    //         guess.col + i,
    //         guess.answer.charAt(i)
    //       );
    //     }
    //   }
    //   if (guess.dir === "down") {
    //     for (let i = 0; i < guess.answer.length; i++) {
    //       puzzEl.current.setGuess(
    //         guess.row + i,
    //         guess.col,
    //         guess.answer.charAt(i)
    //       );
    //     }
    //   }
    // });

    if (userGuesses) {
      const scoreboard = Object.values(userGuesses).reduce(
        (acc, guess) => {
          if (guess[0]) {
            if (acc.players[guess[0].userId]) {
              acc.players[guess[0].userId] = acc.players[guess[0].userId] + 1;
            } else {
              acc.players[guess[0].userId] = 1;
            }
          }
          if (gameMode === "teams") {
            if (acc.teams[guess[0].team]) {
              acc.teams[guess[0].team] = acc.teams[guess[0].team] + 1;
            } else {
              acc.teams[guess[0].team] = 1;
            }
          }
          return acc;
        },
        { players: {}, teams: {} }
      );

      setScoreboard(scoreboard);
    }
  }, [puzzEl, guesses, userGuesses, setScoreboard, gameMode]);

  useEffect(() => {
    calculateScores();
  }, [guesses, puzzEl, puzzleLoaded, calculateScores]);

  //   useEffect(() => {
  //     if (puzzEl.current) {
  //       puzzEl.current.onLoadedCorrect(setPuzzleLoaded);
  //     }
  //   }, [puzzEl]);

  let correct = 0;
  let all = puzzleData.allAnswers.length;

  const displayAnswers = puzzleData?.allAnswers.map((answer, i) => {
    if (guesses && guesses[answer]) {
      correct++;
      return (
        <div className="answered">
          <div>
            {gameMode === "teams" ? (
              <TeamEmoji
                style={{ marginLeft: 6 }}
                team={guesses[answer].team}
              />
            ) : (
              <Avatar
                style={{ display: "inline-block", marginTop: -30 }}
                width={30}
                height={30}
                userId={guesses[answer]?.userId}
              />
            )}
            <span className="clue">{puzzleData.clues[answer].clue}</span>
          </div>
          <div className="answer">{answer}</div>{" "}
        </div>
      );
    } else if (roundActive === false) {
      return (
        <div className="revealed">
          <div>
            <span>{i + 1}.</span>
            <span className="clue">{puzzleData.clues[answer].clue}</span>
          </div>
          <div className="answer">{answer}</div>{" "}
        </div>
      );
    } else {
      return (
        <div className="open">
          <div>
            <span>{i + 1}.</span>
            <span />
            <span className="clue">{puzzleData.clues[answer].clue}</span>
          </div>
          <div className="placeholder">
            {puzzleData.clues[answer].answer.split(" ").map((word) => (
              <>
                <span className="word">
                  {word
                    .split("")
                    .map((char) =>
                      char === " " ? (
                        <span className="space" />
                      ) : (
                        <span className="blank" />
                      )
                    )}
                </span>{" "}
              </>
            ))}
          </div>
        </div>
      );
    }
  });

  console.log("scoreboard", scoreboard);
  return (
    <QuizWrapper>
      <div>
        <h1>{puzzleData.name}</h1>
        <p>
          Guessed {correct}/{all} - {Math.floor((correct * 100) / all)}%
        </p>
        <div className="answers">{displayAnswers}</div>
      </div>
      {gameMode === "teams" ? (
        <div style={{ display: "flex" }}>
          {Object.keys(playerLists).map((team, i) => (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 20,
                  fontSize: 36,
                }}
              >
                <TeamEmoji team={team} />
                {scoreboard.teams[team] || "0"}
              </div>
              {playerLists[team].map((player) => (
                <Avatar userId={player} width={30} height={30} />
              ))}
            </div>
          ))}
        </div>
      ) : (
        <div style={{ display: "flex" }}>
          {Object.keys(scoreboard.players).map((userId, i) => (
            <div
              style={{ display: "flex", alignItems: "center", marginRight: 20 }}
            >
              <Avatar width={50} height={50} userId={userId} />
              {scoreboard?.players[userId]}
            </div>
          ))}
        </div>
      )}
    </QuizWrapper>
  );
};

export default QuizPuzzle;
