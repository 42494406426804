import React, { Component } from "react";
import {
  CardElement,
  injectStripe,
  StripeProvider,
  Elements,
  PaymentRequestButtonElement
} from "react-stripe-elements";

class _PayForm extends React.Component {
  constructor(props) {
    super(props);

    // For full documentation of the available paymentRequest options, see:
    // https://stripe.com/docs/stripe.js#the-payment-request-object
    const paymentRequest = props.stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: "Super Request!",
        amount: 100
      }
    });

    paymentRequest.on("token", ({ complete, token, ...data }) => {
      console.log("Received Stripe token: ", token);
      console.log("Received customer information: ", data);
      if (props.onComplete) {
        props.onComplete({ token: token, data: data });
      }
      complete("success");
    });

    paymentRequest.canMakePayment().then(result => {
      console.log("canMakePayment", result);
      this.setState({ canMakePayment: !!result });
    });

    this.state = {
      canMakePayment: false,
      paymentRequest
    };
  }
  render() {
    return (
      <div style={{ width: "100%" }}>
        {this.state.canMakePayment ? (
          <PaymentRequestButtonElement
            paymentRequest={this.state.paymentRequest}
            className="PaymentRequestButton"
            style={{
              paymentRequestButton: {
                theme: "dark",
                height: "64px"
              }
            }}
          />
        ) : null}
        {/* <CardElement style={{ base: { fontSize: "18px" } }} /> */}
      </div>
    );
  }
}
const PayForm = injectStripe(_PayForm);
class Pay extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <StripeProvider apiKey={this.props.stripePublicKey}>
        <Elements>
          <PayForm {...this.props} />
        </Elements>
      </StripeProvider>
    );
  }
}

export default Pay;
