import React, { Fragment, useEffect, useState } from "react";
import firebase from "./firebase";
import {
  useDocumentData,
  useCollectionData,
} from "react-firebase-hooks/firestore";
import styled, { css, keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import random from "random";
import seedrandom from "seedrandom";

import Avataaar, { Piece } from "avataaars";

const Laptop = styled.div`
  background: url(/img/laptop${({ platform }) =>
    platform === "" ? "" : `-${platform}`}.png);
  background-size: 100% 100%;
  width: ${({ platform }) =>
    platform.match(/iphone|android/) ? "48px" : "130px"};
  height: 90px;
  display: block;
  position: absolute;
  z-index: 50;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
`;

const Name = styled.div`
  position: absolute;
  background: black;
  color: white;
  top: -30px;
  border-radius: 10px;
  padding: 4px;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
`;

const bob = keyframes`
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(3%);
    }
    100% {
        transform: translateY(0);
    }
`;
const AvatarWrapper = styled.div`
  //   display: flex;
  //   flexdirection: column;
  //   overflow: hidden;
  width: ${({ width }) => width || "200"}px;
  height: ${({ height }) => height || "200"}px;
  ${({ x, y }) => {
    if (!x || !y) return "";
    return `left: ${x || "0"}%;
        bottom: ${y || "0"}%;
        position: absolute;
        z-index: ${100 - y};
        flex: 0 0 auto;
        transition: left ease-out .2s, bottom ease-out .2s; 
        transform: translateX(-50%);`;
  }}

  ${Name} {
    opacity: 0;
    transition: opacity ease-in 0.2s;
  }
  :hover ${Name} {
    opacity: 1;
  }
  :hover {
    z-index: 101;
  }
`;

const StyledAvatar = styled(Avataaar)`
  width: 100%;
  height: 100%;
  > {
    width: 100%;
    height: 100%;
  }
`;

const AvatarAnimation = styled.div`
  > * {
    animation: ${({ status }) =>
        status === "bob"
          ? css`
              ${bob}
            `
          : "none"}
      1s infinite;
  }
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

const Waves = styled.div`
  height: 10%;
  position: absolute;
  bottom: -5%;
  z-index: 1000;
  left: 0;
  right: 0;
  background: url(/img/waves.svg);
  background-size: 100% 100%;
`;

const Avatar = (props) => {
  const userDoc = props.userId
    ? firebase.firestore().collection("users").doc(props.userId)
    : null;
  const [user, userLoading, userError] = useDocumentData(userDoc);

  if (userLoading || userError || !user) return null;
  let x, y;

  if (props.randomPosition) {
    random.use(seedrandom(props.userId));

    x = random.int(20, 80);
    y = random.int(0, 80);
  }

  if (props.savedPosition && user.x && user.y) {
    x = user.x;
    y = user.y;
  }

  return (
    <AvatarWrapper
      width={props.width}
      height={props.height}
      x={x}
      y={y}
      style={props.style}
    >
      {user.displayName && <Name>{user.displayName}</Name>}
      <AvatarAnimation status={props.status}>
        <StyledAvatar
          {...user.avatarStyles}
          {...props.overrideStyles}
          style={{ width: "100%", height: "100%" }}
        />
      </AvatarAnimation>
      {props.showLaptop && (
        <Laptop platform={user.platform ? user.platform : ""} />
      )}
      {props.showWaves && <Waves />}
    </AvatarWrapper>
  );
};

export default Avatar;
