import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/database";

export const FIREBASE_ERRORS = {
  "auth/user-not-found": "Email not found.",
  "auth/wrong-password": "Password incorrect, please try again."
};

// const config = {
//   apiKey: process.env.REACT_APP_FB_API_KEY,
//   authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
//   projectId: process.env.REACT_APP_FB_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID
// };

const config = {
  apiKey: "AIzaSyA540oZrtCO_L5wCNYdMFmT-ANR4u209_Y",
  authDomain: "hoverchat-dev.firebaseapp.com",
  databaseURL: "https://hoverchat-dev.firebaseio.com",
  projectId: "hoverchat-dev",
  storageBucket: "hoverchat-dev.appspot.com",
  messagingSenderId: "140900113207",
  appId: "1:140900113207:web:58e20d4f94a92b6b4a18b2"
};

firebase.initializeApp(config);

export const authProviders = {
  google: new firebase.auth.GoogleAuthProvider()
};

export default firebase;
