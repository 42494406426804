import React, { useState } from "react";
import styled from "styled-components";
import SVG from "react-inlinesvg";

const ImageWrapper = styled.div`
  .color-0,
  .color-1,
  .color-2,
  .color-3,
  .color-4,
  .color-5,
  .color-6,
  .color-7,
  .color-8,
  .color-9,
  .color-10,
  .color-11,
  .color-12,
  .color-13,
  .color-14,
  .color-15 {
    fill: #fff !important;
  }

  zoom: ${({ zoom }) => zoom || "0.5"};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ colors }) =>
    colors
      ? Object.keys(colors).map(
          (key) => `.color-${key} { fill: ${colors[key]} !important; }`
        )
      : ""}
`;

const PaintImage = (props) => {
  const [zoom, setZoom] = useState(0.5);
  return (
    <div>
      <div style={{ position: "fixed", bottom: 0, left: 0 }}>
        <button onClick={() => setZoom(zoom - 0.1)}>Zoom out</button>
        <button onClick={() => setZoom(zoom + 0.1)}>Zoom in</button>
      </div>
      <ImageWrapper colors={props.roundData?.colors || {}} zoom={zoom}>
        <SVG
          preserveAspectRatio="xMidYMid"
          src={props.puzzleData?.imageURL}
          alt="paint"
        />
      </ImageWrapper>
    </div>
  );
};

export default PaintImage;
