import React, { Fragment, useEffect, useState, useRef } from "react";

import { useAuthState } from "react-firebase-hooks/auth";
import {
  useDocumentData,
  useCollectionData,
} from "react-firebase-hooks/firestore";

import firebase, { authProviders } from "./firebase";

import styled, { css, keyframes } from "styled-components";

import MetaTags from "react-meta-tags";
import Pay from "./Pay";
import LiveChatBox from "./LiveChatBox";
import ReactPlayer from "react-player";
import UserList from "./UserList";
import TurntableFan, { AvatarPicker, MoodPicker } from "./TurntableFan";
// import YouTubeSearch from "./YouTubeSearch";
import BingSearch from "./BingSearch";
import AvatarDesigner from "./AvatarDesigner";
import Avatar from "./Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faComments,
  faUsers,
  faCog,
  faExpandArrows,
  faVolumeMute,
  faVolume,
  faSearch,
  faList,
  faLifeRing,
} from "@fortawesome/pro-regular-svg-icons";

const IconButton = styled(FontAwesomeIcon)`
  background: ${({ bgColor }) => (bgColor ? bgColor : "gray")};
  color: ${({ color }) => (color ? color : "white")};
  display: block;
  flex: 1 0 auto;
  width: 30px;
  height: 30px;
  padding: 4px;
  cursor: pointer;
  // border-radius: 4px;
`;

const Menu = styled.div`
  display: flex;
  flex: 0 0 auto;
`;

// Hook
function useEventListener(eventName, handler, element = window) {
  // Create a ref that stores handler
  const savedHandler = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
}
const RequestsList = styled.div`
  height: 200px;
  overflow: auto;
  background: #ddd;
  flex: 1 0 auto;

  @media (max-width: 768px) {
    height: 80px;
  }
`;
const RequestItem = styled.div`
  display: flex;
  padding: 8px;
`;
const Thumbnail = styled.img`
  width: 90px;
  object-fit: cover;
  height: 50px;
  flex: 0 0 auto;
  margin-right: 10px;
`;
const RoomTitle = styled.div`
  background: #111;
  color: #fff;
  //   height: 20px;
  flex: 0 0 auto;
  padding: 4px;
`;
const LiveChatWrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledPlayer = styled(ReactPlayer)`
  flex: 1 1 auto;
  align-self: center;

  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    bottom: 0;
  }
`;

const ClubPlayer = styled(ReactPlayer)`
  flex: 1 1 auto;
  align-self: center;
`;

const ClubPlayerWrapper = styled.div`
  position: absolute;
  left: 50%;
  background: #333;
  z-index: 1;
  transform: translateX(-50%);
  height: 557px;
  width: 1000px;
  bottom: 100px;
`;

const Stage = styled.div`
  background: url(/img/stage-bg.png);
  background-size: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 60%;
  position: absolute;
  background-position: center bottom;
`;

const water = keyframes`
  from {
    background: #87a0dc;
  }
  to {
    background: #9cb4ef;
  }
`;
const Floor = styled.div`
  background: url(/img/floor.jpg);
  background-size: 100% 100%;
  background-position: center center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%;

  ${({ roomStyle }) =>
    roomStyle === "pool" &&
    css`
      background: #9cb4ef;
      animation: ${water} 2s infinite;
      animation-direction: alternate;
    `}
`;

const Table = styled.div`
  background: url(/img/dj-table.png);
  width: 259px;
  height: 29px;
  background-size: cover;
  position: absolute;
  left: 50%;
  bottom: 89px;
  z-index: 21;
  transform: translateX(-50%);
`;

const Board = styled.div`
  background: url(/img/board.png);
  width: 529px;
  height: 140px;
  background-size: cover;
  position: absolute;
  left: 50%;
  bottom: -40px;
  z-index: 20;
  transform: translateX(-50%);
`;

const Rigging = styled.div`
  background: url(/img/stage_b.png);
  background-size: auto 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -60px;
  top: -40px;
  z-index: 1;
  background-repeat: no-repeat;
  background-position: bottom;
  pointer-events: none;
`;

const UpVote = styled.div`
  background-image: url(/img/up.png);
  :hover {
    background-image: url(/img/up-hover.png);
  }
  ${({ active }) =>
    active && `background-image: url(/img/up-active.png) !important;`}
  width: 62px;
  height: 107px;
  cursor: pointer;
  background-size: 100% 100%;
`;

const StageUpVote = styled(UpVote)`
  position: absolute;
  right: 15px;
  top: 15px;
`;

const RequestUpVote = styled(UpVote)`
  width: 31px;
  height: 53px;
  flex: 0 0 auto;
  margin-left: 10px;
`;

const ScrollingTitle = styled.div`
  font-family: "DS Dots Medium";
  color: yellow;

  font-size: 40px;
  overflow: hidden;
  white-space: nowrap;
  line-height: 40px;
  pointer-events: none;
  margin: 33px 90px 6px;
`;

const DJName = styled.div`
  font-family: "DS Dots Medium";
  color: yellow;
  font-size: 24px;
  overflow: hidden;
  white-space: nowrap;
  pointer-events: none;
  line-height: 20px;
  margin: 0 90px 0;
  text-align: center;
`;

const PlayerWrapper = styled.div`
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  background: #333;
  overflow: hidden;
  position: relative;

  @media (max-width: 768px) {
    position: relative;
    flex: 0 1 auto;
    height: auto;
    width: 100%;

    &:after {
      display: block;
      padding-bottom: 56.25%;
      height: 0;
      content: " ";
    }
  }
`;

const Upvotes = styled.span`
  padding: 20px 4px;
  font-weight: bold;
`;
const Requests = ({ roomId, nowPlaying, user, isAdmin }) => {
  const [requests, requestsLoading, requestsError] = useCollectionData(
    firebase
      .firestore()
      .collection("requests")
      .where("roomId", "==", roomId)
      .where("played", "==", false)
      // .where("playing", "==", false)
      .orderBy("upvotes", "desc")
      .orderBy("videoUpdatedAt", "asc"),
    { idField: "id" }
  );

  if (requestsLoading) {
    return <p>Loading requests</p>;
  }
  if (requestsError) {
    console.error(requestsError);
    return <p>Error loading requests</p>;
  }
  return (
    <RequestsList>
      {requests &&
        requests
          .filter((request) => !(nowPlaying && nowPlaying === request.id))
          .map((request) => {
            return (
              <RequestItem key={request.id}>
                {request.thumbnail && (
                  <Thumbnail
                    src={request.thumbnail}
                    onClick={
                      isAdmin
                        ? () => {
                            var playRequestNow = firebase
                              .functions()
                              .httpsCallable("playRequestNow");
                            playRequestNow({ requestId: request.id }).then(
                              (result) => {
                                console.log(result);
                              }
                            );
                          }
                        : null
                    }
                  />
                )}
                <div
                  style={{
                    flex: "1 1 auto",
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <VideoTitle>
                    {request.title ? request.title : request.videoUrl}
                  </VideoTitle>
                  {request.videoUpdatedBy && (
                    <VideoDetail>Added by {request.videoUpdatedBy}</VideoDetail>
                  )}
                </div>
                {((user && request.userId === user.uid) || isAdmin) && (
                  <IconButton
                    onClick={() => {
                      firebase
                        .firestore()
                        .collection("requests")
                        .doc(request.id)
                        .delete();
                    }}
                    icon={faTimes}
                    fixedWidth
                    size="lg"
                    color="red"
                    bgColor="transparent"
                  />
                )}
                <Upvotes>{request.upvotes}</Upvotes>
                {user && (
                  <RequestUpVote
                    active={user && request.voters.includes(user.uid)}
                    onClick={() => {
                      let updateData = {};
                      if (user && request.voters.includes(user.uid)) {
                        updateData = {
                          voters: firebase.firestore.FieldValue.arrayRemove(
                            user.uid
                          ),
                        };
                      } else {
                        updateData = {
                          voters: firebase.firestore.FieldValue.arrayUnion(
                            user.uid
                          ),
                        };
                      }
                      firebase
                        .firestore()
                        .collection("requests")
                        .doc(request.id)
                        .update(updateData);
                    }}
                  />
                )}
              </RequestItem>
            );
          })}
    </RequestsList>
  );
};
const NowPlayingWrapper = styled.div`
  background: #666;
  color: #fff;
  display: flex;
  padding: 8px;
  flex: 0 0 auto;
`;
const VideoTitle = styled.span`
  font-weight: bold;
  font-size: 16px;
  display: block;
  flex: 1 1 auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
  overflow: hidden;
`;
const VideoDetail = styled.span`
  display: block;
  font-size: 12px;
`;
const NowPlaying = ({ requestId }) => {
  const [request, requestLoading, requestError] = useDocumentData(
    firebase.firestore().doc(`requests/${requestId}`)
  );
  // console.log("Request!", requestId, request, requestLoading, requestError);
  if (requestLoading) return <p>Loading</p>;
  if (requestError) return <p>Error</p>;

  if (!request) return null;

  return (
    <NowPlayingWrapper>
      {request.thumbnail && <Thumbnail src={request.thumbnail} />}
      <div style={{ flex: "1 1 auto" }}>
        {request.title && <VideoTitle>{request.title}</VideoTitle>}
        {request.videoUpdatedBy && (
          <VideoDetail>Played by {request.videoUpdatedBy}</VideoDetail>
        )}
      </div>
    </NowPlayingWrapper>
  );
};

const Fan = ({ userId }) => {
  const [user, userLoading, userError] = useDocumentData(
    firebase.firestore().collection("users").doc(userId),
    {
      idField: "id",
    }
  );
  // console.log("usercheck", user, userLoading, userError);
  if (userLoading) {
    return null;
    // return <p>Loading user...</p>;
  }
  if (userError || !user) {
    return null;
    // return <p>Error loading user.</p>;
  }
  return <img src={user.photoURL} />;
};

const StyledFans = styled.div`
  position: absolute;
  bottom: 0%;
  left: 0;
  width: 100%;
  z-index: 999;
  display: flex;
  height: 70%;

  flex-wrap: wrap-reverse;
  align-items: flex-end;
  justify-content: space-around;
`;

const Fans = (props) => {
  return (
    <StyledFans>
      {props.users &&
        props.users.map((user) => {
          return (
            <Avatar
              userId={user}
              key={user}
              status={
                props.voters && props.voters.includes(user) ? "bob" : "normal"
              }
              showWaves={props.roomStyle === "pool"}
              randomPosition
              savedPosition
            />
          );
        })}
    </StyledFans>
  );
};

const Club = styled.div`
  position: relative;
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  overflow: hidden;

  @media (max-width: 768px) {
    zoom: 0.5;
    height: 40%;
  }
`;

const DJ = styled.div`
  position: absolute;
  left: 50%;
  bottom: 118px;
  transform: translateX(-50%);
  z-index: 30;
`;

const Controls = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
  display: flex;

  ${IconButton} {
    margin: 0px;
    border-radius: 50px;
    width: 20px;
    background: transparent;
    padding: 5px;
    height: 20px;
  }
`;

const Room = ({ roomId }) => {
  const [user, initializing, error] = useAuthState(firebase.auth());
  const [menu, setMenu] = useState("chat");
  const [roomStyle, setRoomStyle] = useState("club");
  const [menuB, setMenuB] = useState("requests");
  const [mute, setMute] = useState(false);
  const [room, roomLoading, roomError] = useDocumentData(
    firebase.firestore().doc(`rooms/${roomId}`),
    {
      idField: "id",
    }
  );
  const [request, requestLoading, requestError] = useDocumentData(
    room && room.nowPlaying
      ? firebase.firestore().collection("requests").doc(room.nowPlaying)
      : null,
    { idField: "id" }
  );

  const isAdmin = room && user && room.admins && room.admins.includes(user.uid);

  const playerRef = useRef();
  const floorRef = useRef();
  let firstRun = true;
  useEffect(() => {
    firstRun = true;
    [
      "/img/stage-bg.png",
      "/img/up.png",
      "/img/up-hover.png",
      "/img/up-active.png",
      "/img/stage_b.png",
      "/img/floor.png",
    ].forEach((picture) => {
      const img = new Image();
      img.src = picture.fileName;
    });
  }, [firstRun]);

  //   console.log("room", room);
  const login = () => {
    firebase.auth().signInWithPopup(authProviders.google);
  };
  const logout = () => {
    firebase.auth().signOut();
  };
  if (roomLoading) {
    return <p>Loading...</p>;
  }
  if (!room) {
    return <p>Room not found</p>;
  }

  const syncPlayer = () => {
    playerRef.current.seekTo(
      Math.round(Date.now() / 1000 - room.videoUpdatedAt.seconds)
    );
  };

  const videoEnded = () => {
    // console.log("videoEnded!");

    firebase
      .firestore()
      .collection("requests")
      .doc(room.nowPlaying)
      .update({
        played: true,
        endRequested: firebase.firestore.FieldValue.arrayUnion(
          user ? user.uid : "anonymous"
        ),
      });
  };

  const isMobile = window.matchMedia("(max-width: 600px)").matches;
  // console.log(
  //   "Request!",
  //   user,
  //   room,
  //   roomLoading,
  //   roomError,
  //   room && "requestId" in room ? room.nowPlaying : "abc",
  //   request,
  //   request && request.userId
  // );

  return (
    <Fragment>
      {"jukebox" in room &&
      room.jukebox &&
      (roomStyle === "club" || roomStyle === "pool") ? (
        <Club>
          <MetaTags>
            <title>
              {request && request.title && `${request.title} ${room.name} `}
              &bull; hover.chat
            </title>
            <meta
              name="description"
              content={`Come spin music in hover.chat`}
            />
            <meta
              property="og:title"
              content={
                request
                  ? `Now Playing in ${room.name}: ${request.title}`
                  : room.name
              }
            />
            {request && "thumbnail" in request && (
              <meta property="og:image" content={request.thumbnail} />
            )}
          </MetaTags>

          <Stage>
            <Table />
            <Board>
              {request && (
                <Fragment>
                  <ScrollingTitle>
                    <marquee>{request.title}</marquee>
                  </ScrollingTitle>
                  <DJName>DJ {request.videoUpdatedBy}</DJName>
                  {user && (
                    <StageUpVote
                      active={user && request.voters.includes(user.uid)}
                      onClick={() => {
                        let updateData = {};
                        if (user && request.voters.includes(user.uid)) {
                          updateData = {
                            voters: firebase.firestore.FieldValue.arrayRemove(
                              user.uid
                            ),
                          };
                        } else {
                          updateData = {
                            voters: firebase.firestore.FieldValue.arrayUnion(
                              user.uid
                            ),
                          };
                        }
                        firebase
                          .firestore()
                          .collection("requests")
                          .doc(room.nowPlaying)
                          .update(updateData);
                      }}
                    />
                  )}
                </Fragment>
              )}
            </Board>
            {request ? (
              <DJ
                style={{
                  opacity:
                    room.users && room.users.includes(request.userId)
                      ? 1
                      : 0.75,
                }}
              >
                <Avatar
                  showLaptop
                  userId={request && request.userId}
                  status={
                    request.voters.includes(request.userId) ? "bob" : "normal"
                  }
                />
              </DJ>
            ) : (
              "no request"
            )}
            <ClubPlayerWrapper>
              <ClubPlayer
                ref={playerRef}
                url={room.videoUrl}
                playing
                height="100%"
                width="100%"
                pip={true}
                muted={mute}
                controls={true}
                onReady={() => {
                  console.log("ready!");
                  syncPlayer();
                }}
                onPlay={() => {
                  console.log("playing!");
                  // syncPlayer();
                  if (
                    Math.abs(
                      playerRef.current.getCurrentTime() -
                        Math.round(
                          Date.now() / 1000 - room.videoUpdatedAt.seconds
                        )
                    ) > 2
                  ) {
                    syncPlayer();
                  }
                }}
                onEnded={videoEnded}
              />
            </ClubPlayerWrapper>
            <Rigging />
          </Stage>
          <Floor
            roomStyle={roomStyle}
            ref={floorRef}
            onClick={(e) => {
              // e.persistEvent()
              var rect = floorRef.current.getBoundingClientRect();
              var x = Math.round(((e.clientX - rect.left) / rect.width) * 100); //x position within the element.
              var y =
                100 - Math.round(((e.clientY - rect.top) / rect.height) * 100); //y position within the element.

              console.log("floor click", x, y);

              firebase
                .firestore()
                .collection("users")
                .doc(user.uid)
                .update({ x: x, y: y });
            }}
          >
            <Fans
              users={
                room.users &&
                room.users.filter(
                  (user) => user && request && user !== request.userId
                )
              }
              voters={request && request.voters}
              roomStyle={roomStyle}
            />
          </Floor>
          <Controls>
            <IconButton
              icon={mute ? faVolume : faVolumeMute}
              onClick={() => {
                setMute(!mute);
              }}
            />
            <IconButton
              icon={faExpandArrows}
              onClick={() => {
                setRoomStyle(roomStyle === "club" ? "full" : "club");
              }}
            />
            <IconButton
              icon={faLifeRing}
              onClick={() => {
                setRoomStyle(roomStyle === "pool" ? "club" : "pool");
              }}
            />
            {/* {document.querySelector("#root").requestFullscreen && (
              <IconButton
                icon={faExpandArrows}
                onClick={() => {
                  let elem = document.querySelector("#root");

                  if (!document.fullscreenElement) {
                    elem.requestFullscreen().catch(err => {
                      alert(
                        `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
                      );
                    });
                  } else {
                    document.exitFullscreen();
                  }
                }}
              />
            )} */}
          </Controls>
        </Club>
      ) : (
        <PlayerWrapper>
          <StyledPlayer
            ref={playerRef}
            url={room.videoUrl}
            playing
            height="100%"
            width="100%"
            pip={true}
            muted={mute}
            controls={true}
            onReady={() => {
              console.log("ready!");
              syncPlayer();
            }}
            onPlay={() => {
              console.log("playing!");
              // syncPlayer();
              if (
                Math.abs(
                  playerRef.current.getCurrentTime() -
                    Math.round(Date.now() / 1000 - room.videoUpdatedAt.seconds)
                ) > 2
              ) {
                syncPlayer();
              }
            }}
            onEnded={videoEnded}
          />
          <Controls>
            <IconButton
              icon={mute ? faVolume : faVolumeMute}
              onClick={() => {
                setMute(!mute);
              }}
            />
            <IconButton
              icon={faExpandArrows}
              onClick={() => {
                setRoomStyle(roomStyle === "club" ? "full" : "club");
              }}
            />
            {/* {document.querySelector("#root").requestFullscreen && (
              <IconButton
                icon={faExpandArrows}
                onClick={() => {
                  let elem = document.querySelector("#root");

                  if (!document.fullscreenElement) {
                    elem.requestFullscreen().catch(err => {
                      alert(
                        `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
                      );
                    });
                  } else {
                    document.exitFullscreen();
                  }
                }}
              />
            )} */}
          </Controls>
        </PlayerWrapper>
      )}
      <LiveChatWrapper>
        {room.jukebox ? (
          <Fragment>
            <RoomTitle>Now Playing in {room.name}</RoomTitle>
            {room.nowPlaying && <NowPlaying requestId={room.nowPlaying} />}
            {!isMobile && (
              <Menu>
                <IconButton
                  onClick={() => {
                    setMenuB("requests");
                  }}
                  icon={faList}
                  color={menuB === "requests" ? "#fffae0" : "white"}
                  bgColor={menuB === "requests" ? "#45821b" : "gray"}
                />
                {user && (
                  <IconButton
                    onClick={() => {
                      setMenuB("search");
                    }}
                    icon={faSearch}
                    color={menuB === "search" ? "#fffae0" : "white"}
                    bgColor={menuB === "search" ? "#45821b" : "gray"}
                  />
                )}
              </Menu>
            )}
            {menuB === "requests" && !isMobile && (
              <Fragment>
                <RoomTitle>Up Next</RoomTitle>
                <Requests
                  user={user}
                  roomId={roomId}
                  nowPlaying={room.nowPlaying}
                  isAdmin={isAdmin}
                />
              </Fragment>
            )}
            {menuB === "search" && !isMobile && user && (
              <Fragment>
                <RoomTitle>Video Search</RoomTitle>
                <BingSearch user={user} roomId={roomId} />
              </Fragment>
            )}
          </Fragment>
        ) : (
          <RoomTitle>{room.name}</RoomTitle>
        )}
        <Menu>
          {isMobile && (
            <IconButton
              onClick={() => {
                setMenu("requests");
              }}
              icon={faList}
              color={menu === "requests" ? "#fffae0" : "white"}
              bgColor={menu === "requests" ? "#45821b" : "gray"}
            />
          )}
          {isMobile && user && (
            <IconButton
              onClick={() => {
                setMenu("search");
              }}
              icon={faSearch}
              color={menu === "search" ? "#fffae0" : "white"}
              bgColor={menu === "search" ? "#45821b" : "gray"}
            />
          )}
          <IconButton
            onClick={() => {
              setMenu("chat");
            }}
            icon={faComments}
            color={menu === "chat" ? "#fffae0" : "white"}
            bgColor={menu === "chat" ? "#45821b" : "gray"}
          />
          <IconButton
            onClick={() => {
              setMenu("users");
            }}
            icon={faUsers}
            color={menu === "users" ? "#fffae0" : "white"}
            bgColor={menu === "users" ? "#45821b" : "gray"}
          />
          {user && (
            <IconButton
              onClick={() => {
                setMenu("settings");
              }}
              icon={faCog}
              color={menu === "settings" ? "#fffae0" : "white"}
              bgColor={menu === "settings" ? "#45821b" : "gray"}
            />
          )}
        </Menu>
        {menu === "users" && <UserList roomId={roomId} />}
        {menu === "settings" && user && <AvatarDesigner userId={user.uid} />}
        {menu === "chat" && (
          <LiveChatBox roomId={roomId} style={{ height: "auto" }} />
        )}
        {menu === "requests" && (
          <Fragment>
            <RoomTitle>Up Next</RoomTitle>
            <Requests
              user={user}
              roomId={roomId}
              nowPlaying={room.nowPlaying}
              isAdmin={isAdmin}
            />
          </Fragment>
        )}
        {menu === "search" && user && (
          <Fragment>
            <RoomTitle>Video Search</RoomTitle>
            <BingSearch user={user} roomId={roomId} />
          </Fragment>
        )}
      </LiveChatWrapper>
    </Fragment>
  );
};
export default Room;
