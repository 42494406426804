import React, { Fragment, useEffect, useState, useRef } from "react";
import Crossword from "@jaredreisinger/react-crossword";

import { useAuthState } from "react-firebase-hooks/auth";
import {
  useDocumentData,
  useCollectionData,
} from "react-firebase-hooks/firestore";

import firebase, { authProviders } from "./firebase";

import styled, { css, keyframes } from "styled-components";

import LiveChatBox from "./LiveChatBox";
import UserList from "./UserList";
import AvatarDesigner from "./AvatarDesigner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faUsers, faCog } from "@fortawesome/pro-regular-svg-icons";
import { useHarmonicIntervalFn, useUpdate } from "react-use";
import Countdown from "./Countdown";
import SpellraiserBattleground from "./SpellraiserBattleground";
import QuizPuzzle from "./QuizPuzzle";
import Countup from "./Countup";
import Duration from "./Duration";

const startNewRound = firebase.functions().httpsCallable("quizStartRound");
const endRound = firebase.functions().httpsCallable("quizEndRound");
const endPuzzle = firebase.functions().httpsCallable("endPuzzle");

const ALPHABET = "abcdefghijklmnopqrstuvwxyz";

const Heading = styled.div`
  font-family: "Metal Mania", cursive;
  font-size: 60px;
  background-color: #ff0000;
  background-image: linear-gradient(315deg, #ff0000 0%, #ffed00 74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const IconButton = styled(FontAwesomeIcon)`
  background: ${({ bgColor }) => (bgColor ? bgColor : "gray")};
  color: ${({ color }) => (color ? color : "white")};
  display: block;
  flex: 1 0 auto;
  width: 30px;
  height: 30px;
  padding: 4px;
  cursor: pointer;
  // border-radius: 4px;
`;

const Menu = styled.div`
  display: flex;
  flex: 0 0 auto;
`;

const LiveChatWrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Stage = styled.div`
  color: white;
  background: #111;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  display: flex;
  flex-direction: column;
  // background: url("/img/dungeon2.png") #111 no-repeat center;
  background-size: cover;
  image-rendering: pixelated;

  button {
    cursor: pointer;
    background: white;
    color: black;
    border: 0;
    font-size: 16px;
    padding: 8px;
    border-radius: 8px;
  }

  .roundResult {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 24px;
    font-weight: bold;
    // font-family: "Metal Mania", cursive;
    margin-top: 10px;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .phrase {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  //   .word {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     margin: 0 10px;
  //   }

  .guess.letter {
    width: 30px;
    height: 50px;
    background: white;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
    font-family: Helvetica, sans-serif;

    &.blank {
      background: white;
      color: black;
    }

    &.correct {
      background: #538d4e;
      color: white;
    }
  }

  .guess.phrase {
    height: 50px;
    padding: 0 5px;
    background: white;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
    font-family: Helvetica, sans-serif;
    white-space: nowrap;
  }

  .phraseGuesses {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .spacer {
    width: 30px;
    background: white;
  }

  .votes {
    display: flex;
    margin: 10px 0;
    align-items: center;
    justify-content: center;
  }

  .vote {
    // border: 1px solid white;
    // border-radius: 100px;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 10px;
    margin: 0 5px;
    white-space: nowrap;
    font-weight: bold;
  }

  .alphabet {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    margin-bottom: 10px;
  }

  .guess.small {
    background: transparent;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 18px;
    height: 30px;
    //   margin: auto;

    &.blank {
      background: white;
      color: black;
    }

    &.guessed,
    &.incorrect {
      background: #bb0000;
    }

    &.correct {
      opacity: 1;
      background: #538d4e;
      color: white;
    }
  }

  .guess.letter.small {
    width: 20px;
  }

  .battleground {
    display: flex;
    align-items: flex-end;
    // justify-content: flex-end;
    margin-bottom: 40px;
    width: 100%;

    .audience {
      //   margin-bottom: 60px;

      .sprite {
        transform: scaleX(-1);
      }

      .spriteGroup {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .player {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .displayName {
        margin-top: 10px;
      }

      &.active .displayName {
        font-weight: bold;
        // border-bottom: 6px solid blue;
      }
    }
  }

  .speedGuesses {
    display: flex;
    width: 100%;

    > div {
      width: 50%;
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;

const QuizRoom = ({ roomId }) => {
  const [user, initializing, error] = useAuthState(firebase.auth());
  const [menu, setMenu] = useState("chat");
  const [endTime, setEndTime] = useState(null);
  const [newPuzzle, setNewPuzzle] = useState(null);
  const [room, roomLoading, roomError] = useDocumentData(
    firebase.firestore().doc(`rooms/${roomId}`),
    {
      idField: "id",
    }
  );
  const [puzzle, puzzleLoading, puzzleError] = useDocumentData(
    firebase.firestore().doc(`quizPuzzles/${room?.quizPuzzleId}`),
    {
      idField: "id",
    }
  );
  const [round, roundLoading, roundError] = useDocumentData(
    firebase.firestore().doc(`quizRounds/${room?.roundId}`),
    {
      idField: "id",
    }
  );
  const [playerLists, setPlayerLists] = useState({ dogs: [], cats: [] });

  const [teamPlayers, teamPlayersLoading, teamPlayersError] = useCollectionData(
    firebase.firestore().collection("rooms").doc(roomId).collection("teams"),
    { idField: "id" }
  );
  //   const [puzzle, setPuzzle] = useState({
  //     id: "",
  //     phrase: "Pedal to the metal",
  //     guesses: ["a"],
  //   });

  useEffect(() => {
    console.log("TEAM PLAYERS", teamPlayers);
    if (teamPlayers) {
      setPlayerLists(
        teamPlayers.reduce(
          (acc, player) => {
            if (room.users.includes(player.id)) {
              if (player.team === "dogs") {
                acc.dogs.push(player.id);
              } else {
                acc.cats.push(player.id);
              }
            }
            return acc;
          },
          { dogs: [], cats: [] }
        )
      );
    }
  }, [teamPlayers, room]);

  console.log("TEAM PLAYERS", teamPlayers, playerLists);
  // const [host, hostLoading, hostError] = useDocumentData(
  //   firebase.firestore().doc(`users/${puzzle?.host}`),
  //   {
  //     idField: "id",
  //   }
  // );
  const [allPuzzles, allPuzzlesLoading, allPuzzlesError] = useCollectionData(
    firebase.firestore().collection("quizPuzzles"),
    {
      idField: "id",
    }
  );

  if (roomLoading) {
    return <p>Loading...</p>;
  }
  if (!room) {
    return <p>Room not found</p>;
  }

  const isMobile = window.matchMedia("(max-width: 600px)").matches;

  console.log("PUZ", room, round, puzzle);

  const puzzleData = puzzle?.clues;
  //   console.log("puzzleData", puzzleData);
  const allMatches = {};

  if (puzzleData) {
    if (round?.guesses) {
      Object.keys(round.guesses).forEach((guess) => {
        // console.log("MATCH", match, guess);
        if (puzzleData[guess]) {
          allMatches[guess] = round.guesses[guess][0];
        }
      });
    }
  }

  //   console.log("allPuzzles", allPuzzles);

  return (
    <Fragment>
      <Stage style={{ overflow: "auto" }}>
        <div className="heading">
          <div style={{ display: "flex" }}>
            <select
              //   onSelect={}
              onSelect={(e) => {
                console.log("onSelect", e);
              }}
              onChange={(e) => {
                console.log("onChagen", e.target.value);
                setNewPuzzle(e.target.value);
              }}
              style={{ flex: "1 1 auto" }}
            >
              <option vlaue="">Select a puzzle</option>
              {allPuzzles?.map((puzzle) => (
                <option value={puzzle.id} key={puzzle.id}>
                  {puzzle.name}
                </option>
              ))}
            </select>
            <button
              onClick={async () => {
                await startNewRound({
                  quizPuzzleId: newPuzzle || "fqadgK4QoIStwG1uRmur",
                  roomId: room.id,
                  gameMode: "individual",
                });
              }}
            >
              New individual game
            </button>
            <button
              onClick={async () => {
                await startNewRound({
                  quizPuzzleId: newPuzzle || "fqadgK4QoIStwG1uRmur",
                  roomId: room.id,
                  gameMode: "teams",
                });
              }}
            >
              New team game
            </button>
          </div>
        </div>
        <div className="middle" style={{ width: "100%" }}>
          <div
            style={{ textAlign: "left", color: "white", alignSelf: "start" }}
          >
            {round?.roundActive ? (
              <Countup startTime={round?.createdAt.toDate()} />
            ) : (
              <>
                Total time:{" "}
                <Duration
                  startTime={round?.roundStarted?.toDate()}
                  endTime={round?.roundEnded?.toDate()}
                />
              </>
            )}
          </div>
          {puzzle ? (
            <div style={{ pointerEvents: "all", width: "100%" }}>
              <QuizPuzzle
                puzzleData={puzzle}
                guesses={allMatches}
                allGuesses={round?.allGuesses}
                userGuesses={round?.guesses}
                playerLists={playerLists}
                gameMode={round?.gameMode}
                roundActive={round?.roundActive}
              />
            </div>
          ) : null}
        </div>
        <div className="footer">
          <p>
            {room?.currentGame} | {room?.roundId} | {room?.quizPuzzleId} |{" "}
            <button onClick={() => endRound({ roundId: room?.roundId })}>
              End round & reveal answers
            </button>
          </p>
        </div>
      </Stage>
      <LiveChatWrapper>
        <Menu>
          <IconButton
            onClick={() => {
              setMenu("chat");
            }}
            icon={faComments}
            color={menu === "chat" ? "#fffae0" : "white"}
            bgColor={menu === "chat" ? "#45821b" : "gray"}
          />
          <IconButton
            onClick={() => {
              setMenu("users");
            }}
            icon={faUsers}
            color={menu === "users" ? "#fffae0" : "white"}
            bgColor={menu === "users" ? "#45821b" : "gray"}
          />
          {user && (
            <IconButton
              onClick={() => {
                setMenu("settings");
              }}
              icon={faCog}
              color={menu === "settings" ? "#fffae0" : "white"}
              bgColor={menu === "settings" ? "#45821b" : "gray"}
            />
          )}
        </Menu>
        {menu === "users" && <UserList roomId={roomId} />}
        {menu === "settings" && user && <AvatarDesigner userId={user.uid} />}
        {menu === "chat" && (
          <LiveChatBox roomId={roomId} style={{ height: "auto" }} />
        )}
      </LiveChatWrapper>
    </Fragment>
  );
};
export default QuizRoom;
