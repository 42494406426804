import React, { Fragment, useEffect, useRef } from "react";
import LiveChatBox from "./LiveChatBox";
import ReactPlayer from "react-player";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  useDocumentData,
  useCollectionData
} from "react-firebase-hooks/firestore";
import { useObjectVal, useListVal } from "react-firebase-hooks/database";
import firebase, { authProviders } from "./firebase";
import styled from "styled-components";

const Avatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 40px;
  margin-right: 10px;
  display: block;
  flex: 0 0 auto;
`;
const UserItem = styled.div`
  color: black;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 8px;
`;
const User = ({ userId }) => {
  const [user, userLoading, userError] = useDocumentData(
    firebase
      .firestore()
      .collection("users")
      .doc(userId),
    { idField: "id" }
  );
  console.log("usercheck", user, userLoading, userError);
  if (userLoading) {
    return null;
    // return <p>Loading user...</p>;
  }
  if (userError || !user) {
    return null;
    // return <p>Error loading user.</p>;
  }
  return (
    <UserItem>
      <Avatar src={user.photoURL} /> <span>{user.displayName}</span>
    </UserItem>
  );
};

const UserList = ({ roomId }) => {
  const [room, roomLoading, roomError] = useDocumentData(
    firebase.firestore().doc(`rooms/${roomId}`),
    { idField: "id" }
  );

  if (roomLoading) {
    return <p>Loading users...</p>;
  }
  if (roomError) {
    return <p>Error.</p>;
  }

  console.log("hi!", room, roomLoading, roomError);

  return (
    <div
      style={{ height: 200, width: "100%", flex: "1 0 auto", overflow: "auto" }}
    >
      {room &&
        room.users &&
        room.users.map(user => {
          return <User userId={user} key={user} />;
        })}
    </div>
  );
};

export default UserList;
