import React, { Fragment, useState } from "react";
import firebase from "./firebase";
import styled from "styled-components";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

const functions = firebase.functions();

const IconButton = styled(FontAwesomeIcon)`
  background: ${({ bgColor }) => (bgColor ? bgColor : "gray")};
  color: ${({ color }) => (color ? color : "white")};
  display: block;
  flex: 1 0 auto;
  width: 30px;
  height: 30px;
  padding: 4px;
  cursor: pointer;
  // border-radius: 4px;
`;
const StyledField = styled(Field)`
  font-size: 15px;
  width: 100%;
  flex-grow: 1;
  padding: 4px;
`;

const StyledButton = styled.button`
  flex-grow: 0;
  color: white;
  border: 0;
  background: #45821b;
  font-size: 18px;
`;
const RequestsList = styled.div`
  height: 200px;
  overflow: auto;
  background: #ddd;
  flex: 1 0 auto;

  @media (max-width: 768px) {
    height: 80px;
  }
`;
const RequestItem = styled.div`
  display: flex;
  padding: 8px;
`;
const Thumbnail = styled.img`
  width: 90px;
  object-fit: cover;
  height: 50px;
  flex: 0 0 auto;
  margin-right: 10px;
`;
const VideoTitle = styled.span`
  font-weight: bold;
  font-size: 16px;
  display: block;
  flex: 1 1 auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
  overflow: hidden;
`;
const VideoDetail = styled.span`
  display: block;
  font-size: 12px;
`;

const BingSearch = ({ user, roomId }) => {
  const [results, setResults] = useState([]);
  const [message, setMessage] = useState([]);
  return (
    <div style={{ flex: "1 0 auto" }}>
      {message && message.length > 0 && <p>{message}</p>}
      <RequestsList>
        {results.map(result => {
          return (
            <RequestItem key={result.id}>
              {result.thumbnail && (
                <Thumbnail src={result.thumbnail} onClick={() => {}} />
              )}
              <div
                style={{ flex: "1 1 auto", overflow: "hidden", width: "100%" }}
              >
                <VideoTitle>
                  {result.title ? result.title : result.videoUrl}
                </VideoTitle>
              </div>
              <IconButton
                icon={faPlus}
                onClick={() => {
                  firebase
                    .firestore()
                    .collection("liveChatMessages")
                    .add({
                      roomId: roomId,
                      user: {
                        name: user.displayName,
                        id: user.uid,
                        pic: user.photoURL
                      },
                      message: result.url,
                      dateCreated: new Date()
                    });
                }}
              />
            </RequestItem>
          );
        })}
      </RequestsList>

      <Formik
        initialValues={{ term: "" }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          // console.log(values, roomId, user);
          resetForm({ term: "" });
          if (values.term === "") {
            return false;
          }

          console.log("search", values.term);

          const bingSearch = functions.httpsCallable("bingSearch");
          bingSearch({ term: values.term })
            .then(data => {
              console.log(data);
              if (data.data.value && data.data.value.length > 0) {
                setResults(
                  data.data.value.map(item => {
                    return {
                      url: item.contentUrl,
                      title: item.name,
                      thumbnail: item.thumbnailUrl,
                      viewCount: item.viewCount,
                      duration: item.duration
                    };
                  })
                );
                setMessage(``);
              } else {
                setResults([]);
                setMessage(`No results`);
              }
            })
            .catch(err => {
              console.error(err);
            });

          //   if (data.items && data.items.length > 0) {
          //     setResults(
          //       data.items.map(item => {
          //         return {
          //           id: item.id.videoId,
          //           title: item.snippet.title,
          //           thumbnail: item.snippet.thumbnails.default.url
          //         };
          //       })
          //     );
          //     setMessage(``);
          //   } else {
          //     setResults([]);
          //     setMessage(`No results`);
          //   }
          // })
          // .catch(err => {
          //   console.error(err);
          //   setResults([]);
          //   setMessage(`Error retrieving YouTube results.`);
          // });
          //   });
        }}
      >
        {({ values, isSubmitting, dirty }) => (
          <Form style={{ display: "flex", flex: "0 0 auto" }}>
            <StyledField name="term" type="textarea" />
            <StyledButton type="submit" disabled={isSubmitting || !dirty}>
              Search
            </StyledButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BingSearch;
