import React, { Fragment, useEffect, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import firebase, { authProviders } from "./firebase";
import {
  useDocumentData,
  useCollectionData
} from "react-firebase-hooks/firestore";
import random from "random";
import seedrandom from "seedrandom";
const AVATARS = {
  21: {
    width: 109,
    height: 194,
    dj: {
      width: 327 / 3,
      height: 772 / 4
    },
    fan: {
      width: 324 / 3,
      height: 768 / 4
    },
    front_bob: {
      width: 327 / 3,
      height: 772 / 4
    },
    back_bob: {
      width: 324 / 3,
      height: 768 / 4
    }
  },
  22: {
    width: 157,
    height: 193,
    dj: {
      width: 471 / 3,
      height: 772 / 4
    },
    fan: {
      width: 465 / 3,
      height: 768 / 4
    },
    front_bob: {
      width: 471 / 3,
      height: 772 / 4
    },
    back_bob: {
      width: 465 / 3,
      height: 768 / 4
    }
  },
  23: {
    width: 268,
    height: 250,
    dj: {
      width: 804 / 3,
      height: 1000 / 4
    },
    fan: {
      width: 885 / 3,
      height: 944 / 4
    },
    front_bob: {
      width: 804 / 3,
      height: 1000 / 4
    },
    back_bob: {
      width: 885 / 3,
      height: 944 / 4
    }
  },
  26: {
    dj: {
      width: 321 / 3,
      height: 608 / 4
    },
    fan: {
      width: 318 / 3,
      height: 616 / 4
    },
    front_bob: {
      width: 321 / 3,
      height: 608 / 4
    },
    back_bob: {
      width: 318 / 3,
      height: 616 / 4
    }
  },
  35: {
    dj: {
      width: 552 / 6,
      height: 842 / 6
    },
    fan: {
      width: 546 / 6,
      height: 942 / 6
    },
    front_bob: {
      width: 552 / 6,
      height: 842 / 6
    },
    back_bob: {
      width: 546 / 6,
      height: 942 / 6
    }
  },
  424: {
    dj: {
      width: 309 / 3,
      height: 536 / 4
    },
    fan: {
      width: 309 / 3,
      height: 536 / 4
    },
    front_bob: {
      width: 309 / 3,
      height: 536 / 4
    },
    back_bob: {
      width: 309 / 3,
      height: 536 / 4
    }
  },
  "4fe0b5d6eb35c12d810000f1": {
    dj: {
      width: 324 / 3,
      height: 652 / 4
    },
    fan: {
      width: 324 / 3,
      height: 648 / 4
    },
    front_bob: {
      width: 324 / 3,
      height: 652 / 4
    },
    back_bob: {
      width: 324 / 3,
      height: 648 / 4
    }
  }
};

const MOODS = {
  fan: {
    label: "fan"
  },
  dj: {
    label: "dj"
  },
  front_bob: {
    label: "front Bob"
  },
  back_bob: {
    label: "back Bob"
  }
};
const animationBobX = keyframes`
0% {
    background-position-x: 0;
}
100% {
    background-position-x: -300%;
}
`;
const animationBobY = keyframes`
0% {
    background-position-y: 0;
}
100% {
    background-position-y: -400%;
}
`;
const animationRockX = keyframes`
0% {
    background-position-x: 0;
}
100% {
    background-position-x: -400%;
}
`;
const animationRockY = keyframes`
0% {
    background-position-y: 0;
}
100% {
    background-position-y: -600%;
}
`;

const Laptop = styled.div`
  background: url(/img/laptop${({ platform }) =>
      platform === "" ? "" : `-${platform}`}.png);
  background-size: 100% 100%;
  width: ${({ platform }) =>
    platform.match(/iphone|android/) ? "48px" : "130px"};
  height: 90px;
  display: block;
  position: absolute;
  z-index: 50;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const Name = styled.div`
  position: absolute;
  background: black;
  color: white;
  top: -30px;
  border-radius: 10px;
  padding: 4px;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
`;

const StyledWrapper = styled.div`
flex: 0 0 auto;
  zoom: 0.75;
  background-image: ${({ number, status }) =>
    `url(/avatars/${number}_${status}.png)`};
  height: ${({ number, status }) => AVATARS[number][status].height}px;
  width: ${({ number, status }) => AVATARS[number][status].width}px;
  background-position: 0 0;
  position: relative;
  ${({ x, y }) =>
    x &&
    y &&
    `
  position: absolute;
  bottom: ${y}%;
  left: ${x}%;
  z-index: ${100 - y};
  transform: translateX(-50%);
  `}
  //   background-repeat: no-repeat;
  ${({ status, number }) =>
    status === "dj" &&
    `background-image: url(/avatars/${number}_front_bob.png);`}
    ${({ status, number }) =>
      status === "fan" &&
      `background-image: url(/avatars/${number}_back_bob.png);`}
  ${({ status }) =>
    status.match(/front_bob|back_bob/) &&
    css`
      animation: ${animationBobX} 0.2s steps(3) infinite,
        ${animationBobY} 0.8s steps(4) infinite;
    `}
  ${({ status }) =>
    status === "front_rock" &&
    css`
      animation: ${animationRockX} 0.1s steps(4) infinite,
        ${animationRockY} 0.6s steps(6) infinite;
    `}

    ${Name} {
        opacity: 0;
        transition: opacity ease-in .2s;
    }
    :hover ${Name} {
        opacity: 1;
    }
    :hover {
        z-index: 101
    }
`;

const TurntableFan = props => {
  const [user, userLoading, userError] = useDocumentData(
    firebase
      .firestore()
      .collection("users")
      .doc(props.userId)
  );

  const [avatars, avatarsLoading, avatarsError] = useCollectionData(
    firebase.firestore().collection("avatars"),
    { idField: "id" }
  );

  if (!user || userLoading || userError) return null;

  let avatarId =
    "avatarId" in user && user.avatarId in AVATARS ? user.avatarId : "21";
  let x, y;
  if (props.randomPosition) {
    // change the underlying pseudo random number generator
    // by default, Math.random is used as the underlying PRNG
    random.use(seedrandom(avatarId + props.userId + avatarId));

    // let x = Math.floor(Math.random() * Math.floor(70)) + 10;
    // let y = Math.floor(Math.random() * Math.floor(80));
    x = random.int(20, 80);
    y = random.int(0, 80);

    // console.log(x, y);
  }

  // console.log("rendering fan!", user, avatarId, user.mood);
  return (
    <Fragment>
      <StyledWrapper
        number={avatarId}
        status={props.status || user.mood || "fan"}
        x={x}
        y={y}
        {...AVATARS[avatarId]}
      >
        {props.showLaptop && (
          <Laptop platform={user.platform ? user.platform : ""} />
        )}
        <Name>{user.displayName}</Name>
      </StyledWrapper>
    </Fragment>
  );
};

const AvatarOption = styled.div`
  zoom: 0.5;
`;

export const AvatarPicker = props => {
  return (
    <div style={{ display: "flex", overflow: "auto" }}>
      {Object.keys(AVATARS).map(avatar => (
        <AvatarOption key={avatar}>
          <img
            src={`/avatars/${avatar}_FullFront.png`}
            width={AVATARS[avatar].width}
            height={AVATARS[avatar].height}
            onClick={e => {
              firebase
                .firestore()
                .collection("users")
                .doc(props.userId)
                .update({ avatarId: avatar });
            }}
          />
        </AvatarOption>
      ))}
    </div>
  );
};

export const MoodPicker = props => {
  return (
    <div>
      {Object.keys(MOODS).map(mood => (
        <button
          key={mood}
          onClick={e => {
            firebase
              .firestore()
              .collection("users")
              .doc(props.userId)
              .update({ mood: mood });
          }}
        >
          {MOODS[mood].label}
        </button>
      ))}
    </div>
  );
};
export default TurntableFan;
