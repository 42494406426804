import React from "react";

const Duration = (props) => {
  //   console.log("Countdown", props.endTime, new Date());
  console.log(props.startTime, props.endTime);
  if (!props.startTime || !props.endTime) return null;
  //   console.log(new Date().getTime(), props.startTime.getTime());
  const totalSeconds =
    (props.endTime.getTime() - props.startTime.getTime()) / 1000;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.round(totalSeconds - minutes * 60);
  return (
    <>
      {minutes}:{String(seconds).padStart(2, "0")}
    </>
  );
};

export default Duration;
