import React, { Fragment, useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import styled from "styled-components";
import firebase, { authProviders } from "./firebase";
import { Formik, Form, Field } from "formik";
import Linkify from "react-linkify";
import Presence from "./Presence";
import platform from "platform";
import Avatar from "./Avatar";
// import Notification from "react-web-notification";

const addChat = firebase.functions().httpsCallable("addChat");

const ranges = [
  "\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]",
  " ", // Also allow spaces
].join("|");

const removeEmoji = (str) => str.replace(new RegExp(ranges, "g"), "");

const isOnlyEmojis = (str) => !removeEmoji(str).length;

const StyledCompose = styled.div`
  form {
    display: flex;
  }
  flex-grow: 0;
`;

const StyledMessage = styled.div`
  overflow-wrap: break-word;
  word-wrap: break-word;
  overflow: hidden;
`;

const StyledMessageWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  flex: 0 0 auto;
  width: 100%;
`;
const StyledMessages = styled.div`
  overflow: auto;
  scroll-behavior: smooth;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column-reverse;
  padding: 10px;
  flex: 1 1 auto;
`;

const StyledWrapper = styled.div`
  // min-width: 250px;
  // display: flex;
  // flex-direction: column;
  // flex: 1 1 auto;
  // // height: 100%;
`;

const StyledUsername = styled.span`
  font-size: 13px;
  display: block;
  color: #666;
`;

const StyledField = styled(Field)`
  font-size: 15px;
  width: 100%;
  flex-grow: 1;
  padding: 4px;
`;

const StyledButton = styled.button`
  flex-grow: 0;
  color: white;
  border: 0;
  background: #45821b;
  font-size: 18px;
`;

const StyledCard = styled.div`
  display: flex;
  flex: 0 0 auto;
  height: 20px;
  padding: 10px;
  background: #eee;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

const OldAvatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 40px;
  margin-right: 10px;
  display: block;
  flex: 0 0 auto;
  position: relative;
`;

const Indicator = styled.div`
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  background: ${({ active }) => (active ? "green" : "gray")};
  border-radius: 30px;
`;

const Button = styled.button`
  font-size: 16px;
  background: #111;
  color: #fff;
  cursor: pointer;
  border: 0;
  border-radius: 4px;
`;

const StyledMessageText = styled.div`
  font-size: ${({ onlyEmojis }) => (onlyEmojis ? "32px" : "14px")};
`;

const SystemMessage = styled.div`
  font-size: 14px;
  font-style: italic;
  color: #666;
`;

const handlePermissionGranted = () => {
  console.log("Permission Granted");
};
const handlePermissionDenied = () => {
  console.log("Permission Denied");
};
const handleNotSupported = () => {
  console.log("Web Notification not Supported");
};

const handleNotificationOnClick = (e, tag) => {
  console.log(e, "Notification clicked tag:" + tag);
};

const UserCard = () => {
  const [user, initializing, error] = useAuthState(firebase.auth());

  useEffect(() => {
    console.log("this should only fire once");
    if (user) {
      let userPlatform = "";
      if (platform && platform.os && platform.os.family) {
        if (platform.os.family.match("Windows")) {
          userPlatform = "windows";
        }
        if (platform.os.family.match("Android")) {
          userPlatform = "android";
        }
        if (platform.os.family.match("iOS")) {
          userPlatform = "iphone";
        }
        if (platform.os.family.match(/Debian|Fedora|Red Hat|Ubuntu/)) {
          userPlatform = "linux";
        }
        if (platform.os.family.match(/OS X|MacOS/)) {
          userPlatform = "mac";
        }
      }

      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .set(
          {
            displayName: user.displayName,
            photoURL: user.photoURL,
            platform: userPlatform,
          },
          { merge: true }
        )
        .then((doc) => console.log("updated user"))
        .catch((err) => console.error(err));
    }
  }, [user]);

  return (
    <StyledCard>
      {user ? (
        <Fragment>
          {/* {user.photoURL && <OldAvatar src={user.photoURL} />} */}
          <Avatar userId={user.uid} width={30} height={30} />
          Logged in as {user.displayName}{" "}
          <Button
            onClick={() => firebase.auth().signOut()}
            style={{ justifySelf: "flex-end" }}
          >
            Logout
          </Button>
        </Fragment>
      ) : (
        <div>
          <Button
            onClick={() =>
              firebase.auth().signInWithPopup(authProviders.google)
            }
          >
            Login
          </Button>
        </div>
      )}
    </StyledCard>
  );
};

const LiveChatMessage = ({
  user,
  message,
  id,
  active,
  requestTitle,
  system,
}) => {
  const componentDecorator = (href, text, key) => (
    <a href={href} target="_blank">
      {text}
    </a>
  );

  if (system) {
    return (
      <StyledMessageWrapper key={id}>
        <SystemMessage>{message}</SystemMessage>
      </StyledMessageWrapper>
    );
  }
  if (requestTitle) {
    return (
      <StyledMessageWrapper key={id}>
        <SystemMessage>
          {user && `${user.name} added "${requestTitle}"`}
        </SystemMessage>
      </StyledMessageWrapper>
    );
  }

  return (
    <StyledMessageWrapper key={id}>
      {/* {user.pic && <OldAvatar src={user.pic} />} */}
      <div style={{ flex: "0 0 auto", marginRight: 8 }}>
        <Avatar
          userId={user.id}
          width={40}
          height={40}
          overrideStyles={{ avatarStyle: "Round" }}
        />
      </div>
      <StyledMessage>
        <StyledUsername>
          {user.name} <Indicator active={active} />
        </StyledUsername>
        <StyledMessageText onlyEmojis={isOnlyEmojis(message)}>
          <Linkify componentDecorator={componentDecorator}>{message}</Linkify>
        </StyledMessageText>
      </StyledMessage>
    </StyledMessageWrapper>
  );
};

const LiveChatMessages = ({ chatMessages }) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(scrollToBottom, [chatMessages]);
  window.chatMessages = chatMessages;
  return (
    <StyledMessages>
      <div ref={messagesEndRef} />
      {chatMessages.map((chatMessage) => (
        <LiveChatMessage key={chatMessage.id} {...chatMessage} />
      ))}
      {/* <Notification
        ignore={chatMessages.length === 0}
        notSupported={handleNotSupported}
        onPermissionGranted={handlePermissionGranted}
        onPermissionDenied={handlePermissionDenied}
        onClick={handleNotificationOnClick}
        timeout={5000}
        title={chatMessages.length > 0 ? chatMessages[0].message : ""}
        options={{
          body: chatMessages.length > 0 ? chatMessages[0].user.name : ""
        }}
        disableActiveWindow={true}
      /> */}
    </StyledMessages>
  );
};

const LiveChatCompose = ({ roomId, user }) => {
  return (
    <StyledCompose>
      <Formik
        initialValues={{ message: "" }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          // console.log(values, roomId, user);
          resetForm({ message: "" });
          if (values.message === "") {
            return false;
          }

          addChat({
            chat: {
              roomId: roomId,
              user: {
                name: user.displayName,
                id: user.uid,
                pic: user.photoURL,
              },
              message: values.message || "",
            },
          })
            .then(() => {
              setSubmitting(false);
            })
            .catch((err) => {
              setSubmitting(false);
              resetForm({ message: values.message || "" });
              console.log(err);
            });
        }}
      >
        {({ values, isSubmitting, dirty }) => (
          <Form>
            <StyledField name="message" type="textarea" autocomplete="off" />
            <StyledButton type="submit" disabled={isSubmitting || !dirty}>
              Send
            </StyledButton>
          </Form>
        )}
      </Formik>
    </StyledCompose>
  );
};

const LiveChatBox = ({ roomId }) => {
  const [user, initializing, error] = useAuthState(firebase.auth());

  const [chatMessages, chatMessagesLoading, chatMessagesError] =
    useCollectionData(
      firebase
        .firestore()
        .collection("liveChatMessages")
        .where("roomId", "==", roomId)
        .orderBy("dateCreated", "desc")
        .limit(50),
      { idField: "id" }
    ); //useFirestore(props.name)

  // console.log(chatMessages, chatMessagesLoading, chatMessagesError);
  if (!roomId) {
    return <p>Invalid Room ID</p>;
  }
  if (chatMessagesLoading) {
    return <p>Loading...</p>;
  }
  if (chatMessagesError) {
    return <p>Error!</p>;
  }
  // console.log(user);

  // const user = { name: "Jeff Greco", id: "xyz" };

  return (
    <Fragment>
      <LiveChatMessages chatMessages={chatMessages} />
      {user && <LiveChatCompose user={user} roomId={roomId} />}
      <UserCard />
      <Presence roomId={roomId} />
    </Fragment>
  );
};

export default LiveChatBox;
