import React, { Fragment, useEffect, useState } from "react";
import firebase from "./firebase";
import {
  useDocumentData,
  useCollectionData
} from "react-firebase-hooks/firestore";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import Avatar, { Piece } from "avataaars";

const categories = [
  { id: "topType", label: "Top" },
  { id: "hairColor", label: "Hair Color" },
  { id: "facialHairType", label: "Facial Hair" },
  { id: "accessoriesType", label: "Accessories" },
  { id: "clotheType", label: "Clothes" },
  { id: "clotheColor", label: "Clothes Color" },
  { id: "eyeType", label: "Eyes" },
  { id: "eyebrowType", label: "Eyebrows" },
  { id: "mouthType", label: "Mouths" },
  { id: "skinColor", label: "Skin" }
];
const configs = {
  topType: [
    "LongHairBigHair",
    "LongHairBob",
    "LongHairBun",
    "LongHairCurly",
    "LongHairCurvy",
    "LongHairDreads",
    "LongHairFrida",
    "LongHairFro",
    "LongHairFroBand",
    "LongHairNotTooLong",
    "LongHairShavedSides",
    "LongHairMiaWallace",
    "LongHairStraight",
    "LongHairStraight2",
    "LongHairStraightStrand",
    "ShortHairDreads01",
    "ShortHairDreads02",
    "ShortHairFrizzle",
    "ShortHairShaggyMullet",
    "ShortHairShortCurly",
    "ShortHairShortFlat",
    "ShortHairShortRound",
    "ShortHairShortWaved",
    "ShortHairSides",
    "ShortHairTheCaesar",
    "ShortHairTheCaesarSidePart",
    "NoHair",
    "Eyepatch",
    "Hat",
    "Hijab",
    "Turban",
    "WinterHat1",
    "WinterHat2",
    "WinterHat3",
    "WinterHat4"
  ],
  accessoriesType: [
    "Blank",
    "Kurt",
    "Prescription01",
    "Prescription02",
    "Round",
    "Sunglasses",
    "Wayfarers"
  ],
  hatColor: [
    "Black",
    "Blue01",
    "Blue02",
    "Blue03",
    "Gray01",
    "Gray02",
    "Heather",
    "PastelBlue",
    "PastelGreen",
    "PastelOrange",
    "PastelRed",
    "PastelYellow",
    "Pink",
    "Red",
    "White"
  ],
  hairColor: [
    "Auburn",
    "Black",
    "Blonde",
    "BlondeGolden",
    "Brown",
    "BrownDark",
    "PastelPink",
    "Platinum",
    "Red",
    "SilverGray"
  ],
  facialHairType: [
    "Blank",
    "BeardMedium",
    "BeardLight",
    "BeardMajestic",
    "MoustacheFancy",
    "MoustacheMagnum"
  ],
  facialHairColor: [
    "Auburn",
    "Black",
    "Blonde",
    "BlondeGolden",
    "Brown",
    "BrownDark",
    "Platinum",
    "Red"
  ],
  clotheType: [
    "BlazerShirt",
    "BlazerSweater",
    "CollarSweater",
    "GraphicShirt",
    "Hoodie",
    "Overall",
    "ShirtCrewNeck",
    "ShirtScoopNeck",
    "ShirtVNeck"
  ],
  clotheColor: [
    "Black",
    "Blue01",
    "Blue02",
    "Blue03",
    "Gray01",
    "Gray02",
    "Heather",
    "PastelBlue",
    "PastelGreen",
    "PastelOrange",
    "PastelRed",
    "PastelYellow",
    "Pink",
    "Red",
    "White"
  ],
  graphicType: [
    "Bat",
    "Cumbia",
    "Deer",
    "Diamond",
    "Hola",
    "Pizza",
    "Resist",
    "Selena",
    "Bear",
    "SkullOutline",
    "Skull"
  ],
  eyeType: [
    "Close",
    "Cry",
    "Default",
    "Dizzy",
    "EyeRoll",
    "Happy",
    "Hearts",
    "Side",
    "Squint",
    "Surprised",
    "Wink",
    "WinkWacky"
  ],
  eyebrowType: [
    "Angry",
    "AngryNatural",
    "Default",
    "DefaultNatural",
    "FlatNatural",
    "RaisedExcited",
    "RaisedExcitedNatural",
    "SadConcerned",
    "SadConcernedNatural",
    "UnibrowNatural",
    "UpDown",
    "UpDownNatural"
  ],
  mouthType: [
    "Concerned",
    "Default",
    "Disbelief",
    "Eating",
    "Grimace",
    "Sad",
    "ScreamOpen",
    "Serious",
    "Smile",
    "Tongue",
    "Twinkle",
    "Vomit"
  ],
  skinColor: [
    "Tanned",
    "Yellow",
    "Pale",
    "Light",
    "Brown",
    "DarkBrown",
    "Black"
  ]
};
const AvatarDesigner = props => {
  const userDoc = firebase
    .firestore()
    .collection("users")
    .doc(props.userId);
  const [userProfile, userProfileLoading, userProfileError] = useDocumentData(
    userDoc
  );
  const [avatarStyles, setAvatarStyles] = useState(
    userProfile && userProfile.avatarStyles ? userProfile.avatarStyles : {}
  );
  const [category, setCategory] = useState("topType");

  //   if (userProfileLoading || userProfileError) return null;

  console.log("avatarStyles", avatarStyles);

  useEffect(() => {
    if (Object.keys(avatarStyles).length > 0) {
      userDoc
        .set({ avatarStyles: avatarStyles }, { merge: true })
        .then(resp => {
          console.log("set the styles", props.userId, avatarStyles);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [{ ...avatarStyles }]);
  useEffect(() => {
    if (userProfile && userProfile.avatarStyles) {
      setAvatarStyles({ ...avatarStyles, ...userProfile.avatarStyles });
    }
  }, [userProfileLoading]);

  if (userProfileLoading || userProfileError) return false;

  return (
    <div
      style={{
        display: "flex",
        flex: "1 1 auto",
        flexDirection: "column",
        overflow: "hidden"
      }}
    >
      <Avatar
        style={{
          width: "250px",
          height: "250px",
          flex: "0 0 auto",
          margin: "10px 0px 20px",
          alignSelf: "center"
        }}
        {...avatarStyles}
      />
      <div style={{ flex: "0 0 auto" }}>
        {categories.map(cat => {
          return (
            <button
              onClick={() => {
                setCategory(cat.id);
              }}
              key={cat.id}
            >
              {cat.label}
            </button>
          );
        })}
      </div>
      <div style={{ overflow: "auto", flex: "1 1 auto" }}>
        {configs[category].map(type => {
          let styles = { ...avatarStyles, [category]: type };
          return (
            <div
              key={type}
              onClick={() => {
                console.log("clicky", styles);
                setAvatarStyles({ ...styles, userEdited: true });
              }}
              style={{ float: "left" }}
            >
              <Avatar style={{ width: "100px", height: "100px" }} {...styles} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AvatarDesigner;
