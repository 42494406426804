import React, { Fragment, useEffect, useState, useRef } from "react";

import { useAuthState } from "react-firebase-hooks/auth";
import {
  useDocumentData,
  useCollectionData,
} from "react-firebase-hooks/firestore";

import firebase, { authProviders } from "./firebase";

import styled, { css, keyframes } from "styled-components";

import LiveChatBox from "./LiveChatBox";
import UserList from "./UserList";
import AvatarDesigner from "./AvatarDesigner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faUsers, faCog } from "@fortawesome/pro-regular-svg-icons";
import { useHarmonicIntervalFn, useUpdate } from "react-use";
import Countdown from "./Countdown";
import SpellraiserBattleground from "./SpellraiserBattleground";

const startNewPuzzle = firebase.functions().httpsCallable("startNewPuzzle");
const endPuzzle = firebase.functions().httpsCallable("endPuzzle");

const ALPHABET = "abcdefghijklmnopqrstuvwxyz";

const Heading = styled.div`
  font-family: "Metal Mania", cursive;
  font-size: 60px;
  background-color: #ff0000;
  background-image: linear-gradient(315deg, #ff0000 0%, #ffed00 74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const IconButton = styled(FontAwesomeIcon)`
  background: ${({ bgColor }) => (bgColor ? bgColor : "gray")};
  color: ${({ color }) => (color ? color : "white")};
  display: block;
  flex: 1 0 auto;
  width: 30px;
  height: 30px;
  padding: 4px;
  cursor: pointer;
  // border-radius: 4px;
`;

const Menu = styled.div`
  display: flex;
  flex: 0 0 auto;
`;

const LiveChatWrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Stage = styled.div`
  color: white;
  background: #111;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  display: flex;
  flex-direction: column;
  // background: url("/img/dungeon2.png") #111 no-repeat center;
  background-size: cover;
  image-rendering: pixelated;

  button {
    cursor: pointer;
    background: white;
    color: black;
    border: 0;
    font-size: 16px;
    padding: 8px;
    border-radius: 8px;
  }

  .roundResult {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 24px;
    font-weight: bold;
    // font-family: "Metal Mania", cursive;
    margin-top: 10px;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .phrase {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .word {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
  }

  .guess.letter {
    width: 30px;
    height: 50px;
    background: white;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
    font-family: Helvetica, sans-serif;

    &.blank {
      background: white;
      color: black;
    }

    &.correct {
      background: #538d4e;
      color: white;
    }
  }

  .guess.phrase {
    height: 50px;
    padding: 0 5px;
    background: white;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
    font-family: Helvetica, sans-serif;
    white-space: nowrap;
  }

  .phraseGuesses {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .spacer {
    width: 30px;
    background: white;
  }

  .votes {
    display: flex;
    margin: 10px 0;
    align-items: center;
    justify-content: center;
  }

  .vote {
    // border: 1px solid white;
    // border-radius: 100px;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 10px;
    margin: 0 5px;
    white-space: nowrap;
    font-weight: bold;
  }

  .alphabet {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    margin-bottom: 10px;
  }

  .guess.small {
    background: transparent;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 18px;
    height: 30px;
    //   margin: auto;

    &.blank {
      background: white;
      color: black;
    }

    &.guessed,
    &.incorrect {
      background: #bb0000;
    }

    &.correct {
      opacity: 1;
      background: #538d4e;
      color: white;
    }
  }

  .guess.letter.small {
    width: 20px;
  }

  .battleground {
    display: flex;
    align-items: flex-end;
    // justify-content: flex-end;
    margin-bottom: 40px;
    width: 100%;

    .audience {
      //   margin-bottom: 60px;

      .sprite {
        transform: scaleX(-1);
      }

      .spriteGroup {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .player {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .displayName {
        margin-top: 10px;
      }

      &.active .displayName {
        font-weight: bold;
        // border-bottom: 6px solid blue;
      }
    }
  }

  .speedGuesses {
    display: flex;
    width: 100%;

    > div {
      width: 50%;
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;

const SpellraiserRoom = ({ roomId }) => {
  const [user, initializing, error] = useAuthState(firebase.auth());
  const [menu, setMenu] = useState("chat");
  const [endTime, setEndTime] = useState(null);
  const [room, roomLoading, roomError] = useDocumentData(
    firebase.firestore().doc(`rooms/${roomId}`),
    {
      idField: "id",
    }
  );
  const [puzzle, puzzleLoading, puzzleError] = useDocumentData(
    firebase.firestore().doc(`puzzles/${room?.puzzleId}`),
    {
      idField: "id",
    }
  );
  const [round, roundLoading, roundError] = useDocumentData(
    firebase
      .firestore()
      .doc(`puzzles/${room?.puzzleId}/rounds/${puzzle?.currentRoundId}`),
    {
      idField: "id",
    }
  );
  const [host, hostLoading, hostError] = useDocumentData(
    firebase.firestore().doc(`users/${puzzle?.host}`),
    {
      idField: "id",
    }
  );

  useEffect(() => {
    if (round?.roundEndTime) {
      //   const converted = round?.roundEndTime;
      console.log("hmm", round?.roundEndTime, round?.roundEndTime.toDate());
      setEndTime(round?.roundEndTime.toDate());

      //   console.log(
      //     round?.roundEndTime.toDate().getTime(),
      //     new Date().getTime(),
      //     round?.roundEndTime.toDate().getTime() - new Date().getTime()
      //   );
    }
  }, [round]);

  //   const update = useUpdate();
  //   useHarmonicIntervalFn(() => update(), 1000);

  console.log("No cheating!", { room, puzzle, round, host });

  const isAdmin = room && user && room.admins && room.admins.includes(user.uid);

  //   console.log("room", room);
  const login = () => {
    firebase.auth().signInWithPopup(authProviders.google);
  };
  const logout = () => {
    firebase.auth().signOut();
  };
  if (roomLoading) {
    return <p>Loading...</p>;
  }
  if (!room) {
    return <p>Room not found</p>;
  }

  const isMobile = window.matchMedia("(max-width: 600px)").matches;

  const displayPhrase = [];

  if (puzzle?.phrase) {
    const phraseWords = puzzle?.phrase.split(" ");
    for (let p = 0; p < phraseWords.length; p++) {
      let word = [];
      for (var i = 0; i < phraseWords[p].length; i++) {
        const letter = phraseWords[p][i].toLowerCase();
        if (letter === " ") {
          word.push(" ");
        } else if (
          (puzzle?.guesses && puzzle.guesses.includes(letter)) ||
          round?.guess === puzzle?.phrase ||
          puzzle?.winningTeam
        ) {
          word.push(letter);
        } else {
          word.push("");
        }
      }
      displayPhrase.push(word);
    }
  }

  const letterGuesses = [];
  const phraseGuesses = [];
  if (puzzle?.guesses) {
    for (const guess of puzzle.guesses) {
      if (guess?.length === 1) {
        letterGuesses.push(guess);
      } else {
        phraseGuesses.push(guess);
      }
    }
  }

  console.log("letterGuesses", letterGuesses);
  console.log("phraseGuesses", phraseGuesses);

  return (
    <Fragment>
      <Stage>
        <div className="heading">
          <Heading>SpellRaiser</Heading>
        </div>
        <div className="middle">
          <SpellraiserBattleground
            user={user}
            room={room}
            puzzle={puzzle}
            host={host}
            round={round}
            winningTeam={puzzle?.winningTeam}
            letterGuesses={letterGuesses}
          />
          {puzzle ? (
            <>
              <div className="phrase">
                {displayPhrase.map((word) => (
                  <div className="word">
                    {word.map((letter) =>
                      letter === " " ? (
                        <div className="spacer" />
                      ) : (
                        <div
                          className={`guess letter ${
                            letter !== "" ? "correct" : ""
                          }`}
                        >
                          {letter}
                        </div>
                      )
                    )}
                  </div>
                ))}
              </div>{" "}
              <div style={{ minHeight: 100, width: "100%" }}>
                {round && !round.roundActive ? (
                  <div className="roundResult">
                    {puzzle.gameMode === "speed" ? (
                      <>
                        <div
                          style={{ display: "flex" }}
                          className="speedGuesses"
                        >
                          {round.catsGuess ? (
                            <div>
                              <div
                                className={`guess ${
                                  round.catsGuess?.length > 1
                                    ? "phrase"
                                    : "letter"
                                } ${
                                  round.catsCorrect ? "correct" : "incorrect"
                                }`}
                              >
                                {round.catsGuess}
                              </div>
                              <div>
                                {round.catsCorrect ? "Correct!" : "Incorrect!"}
                              </div>
                            </div>
                          ) : (
                            <div />
                          )}
                          {round.dogsGuess ? (
                            <div>
                              <div
                                className={`guess ${
                                  round.dogsGuess?.length > 1
                                    ? "phrase"
                                    : "letter"
                                } ${
                                  round.dogsCorrect ? "correct" : "incorrect"
                                }`}
                              >
                                {round.dogsGuess}
                              </div>
                              <div>
                                {round.dogsCorrect ? "Correct!" : "Incorrect!"}
                              </div>
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                        {round?.roundActive && (
                          <div>
                            <Countdown endTime={endTime} /> sec remain
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {" "}
                        {round.guess ? (
                          <>
                            <div>
                              {puzzle?.gameMode === "hostVsAudience"
                                ? round?.roundPlayer === "audience"
                                  ? `Audience`
                                  : `Host`
                                : `Team ${
                                    round?.roundPlayer === "cats"
                                      ? "Cats"
                                      : "Dogs"
                                  }`}
                              guesses
                              <div
                                className={`guess ${
                                  round.guess?.length > 1 ? "phrase" : "letter"
                                } ${
                                  round.correctGuess ? "correct" : "incorrect"
                                }`}
                              >
                                {round.guess}
                              </div>
                            </div>
                            {puzzle.gameActive ? (
                              <div>
                                {round.correctGuess
                                  ? "Correct! Guess again!"
                                  : `Incorrect! ${
                                      puzzle?.gameMode === "hostVsAudience"
                                        ? round?.roundPlayer === "audience"
                                          ? `Host`
                                          : `Audience`
                                        : `Team ${
                                            round?.roundPlayer === "cats"
                                              ? "Dogs"
                                              : "Cats"
                                          }`
                                    } guesses next`}
                              </div>
                            ) : (
                              <div>
                                {round.roundPlayer === "audience"
                                  ? `Audience wins!`
                                  : `Host wins!`}
                              </div>
                            )}
                          </>
                        ) : (
                          <div>
                            No guess received!{" "}
                            {puzzle?.gameMode === "hostVsAudience"
                              ? round?.roundPlayer === "audience"
                                ? `Host`
                                : `Audience`
                              : `Team ${
                                  round?.roundPlayer === "cats"
                                    ? "Dogs"
                                    : "Cats"
                                } guesses next`}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <div className="roundResult">
                    {puzzle.gameMode === "speed" ? (
                      <div>Guess now!</div>
                    ) : (
                      <div>
                        Now guessing:{" "}
                        {puzzle?.gameMode === "hostVsAudience"
                          ? round?.roundPlayer === "audience"
                            ? `Audience`
                            : `Host`
                          : `Team ${
                              round?.roundPlayer === "cats" ? "Cats" : "Dogs"
                            }`}
                      </div>
                    )}
                    <div>
                      <Countdown endTime={endTime} /> sec remain
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div style={{ height: 100 }}>
              <p>No active puzzle</p>
            </div>
          )}

          <div style={{ minHeight: 60 }}>
            {puzzle?.gameMode !== "speed" &&
            round?.votes &&
            Object.keys(round.votes).length > 0 ? (
              <div className="votes">
                Current votes:{" "}
                {Object.keys(round.votes).map((key) => (
                  <div className="vote">
                    <div
                      className={`${
                        key.length > 1 ? "phrase" : "letter"
                      } guess small`}
                    >
                      {key}
                    </div>{" "}
                    {round.votes[key.vote]?.total}
                  </div>
                ))}
              </div>
            ) : (
              " "
            )}
            {puzzle?.winningTeam ? (
              <div className="roundResult">
                {puzzle?.winningTeam === "cats" ? "Cats win!" : "Dogs win!"}
              </div>
            ) : null}
            <p style={{ textAlign: "center", marginTop: 80 }}>
              Guessed letters and phrases:
            </p>
            <div className="alphabet">
              {ALPHABET.split("").map((letter) => (
                <span
                  key={letter}
                  className={`small guess letter ${
                    letterGuesses.includes(letter) ? "guessed" : "available"
                  } ${
                    letterGuesses.includes(letter) &&
                    puzzle?.phrase.indexOf(letter) > -1
                      ? "correct"
                      : ""
                  }`}
                >
                  {letter}
                </span>
              ))}
            </div>
            <div className="phraseGuesses">
              {phraseGuesses.map((phrase) => (
                <span
                  className={`small phrase guess ${
                    phrase === puzzle.phrase ? "correct" : "guessed"
                  }`}
                >
                  {phrase}
                </span>
              ))}
            </div>
          </div>
          {!puzzle?.gameActive ? (
            <div>
              <button
                onClick={() =>
                  startNewPuzzle({ roomId, gameMode: "hostVsAudience" })
                }
              >
                Start New 1 v Many
              </button>{" "}
              <button
                onClick={() => startNewPuzzle({ roomId, gameMode: "teams" })}
              >
                Start New Teams
              </button>{" "}
              <button
                onClick={() => startNewPuzzle({ roomId, gameMode: "speed" })}
              >
                Start Speed Teams
              </button>
            </div>
          ) : null}
        </div>
        <div className="footer">
          {round ? (
            <>
              <div>
                Round: {round.roundNumber} | Type: {puzzle.gameMode} | Player:{" "}
                {round.roundPlayer} | Ends at{" "}
                {round.roundEndTime.toDate().toISOString()} | Puzzle{" "}
                {puzzle.gameActive ? "Active" : "Inactive"} | Round{" "}
                {round.roundActive ? "Active" : "Inactive"}
                <button onClick={() => endPuzzle({ puzzleId: puzzle.id })}>
                  End game
                </button>
              </div>
            </>
          ) : null}
        </div>
      </Stage>
      <LiveChatWrapper>
        <Menu>
          <IconButton
            onClick={() => {
              setMenu("chat");
            }}
            icon={faComments}
            color={menu === "chat" ? "#fffae0" : "white"}
            bgColor={menu === "chat" ? "#45821b" : "gray"}
          />
          <IconButton
            onClick={() => {
              setMenu("users");
            }}
            icon={faUsers}
            color={menu === "users" ? "#fffae0" : "white"}
            bgColor={menu === "users" ? "#45821b" : "gray"}
          />
          {user && (
            <IconButton
              onClick={() => {
                setMenu("settings");
              }}
              icon={faCog}
              color={menu === "settings" ? "#fffae0" : "white"}
              bgColor={menu === "settings" ? "#45821b" : "gray"}
            />
          )}
        </Menu>
        {menu === "users" && <UserList roomId={roomId} />}
        {menu === "settings" && user && <AvatarDesigner userId={user.uid} />}
        {menu === "chat" && (
          <LiveChatBox roomId={roomId} style={{ height: "auto" }} />
        )}
      </LiveChatWrapper>
    </Fragment>
  );
};
export default SpellraiserRoom;
