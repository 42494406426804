import Avatar from "./Avatar";
import firebase from "./firebase";
import React, { useEffect, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";

const SpellraiserBattleground = (props) => {
  const { room, round, puzzle, user, host, winningTeam } = props;
  const [playerLists, setPlayerLists] = useState({ dogs: [], cats: [] });

  const [teamPlayers, teamPlayersLoading, teamPlayersError] = useCollectionData(
    firebase.firestore().collection("rooms").doc(room?.id).collection("teams"),
    { idField: "id" }
  );
  //   const [puzzle, setPuzzle] = useState({
  //     id: "",
  //     phrase: "Pedal to the metal",
  //     guesses: ["a"],
  //   });

  useEffect(() => {
    console.log("TEAM PLAYERS", teamPlayers);
    if (teamPlayers) {
      setPlayerLists(
        teamPlayers.reduce(
          (acc, player) => {
            if (room.users.includes(player.id)) {
              if (player.team === "dogs") {
                acc.dogs.push(player.id);
              } else {
                acc.cats.push(player.id);
              }
            }
            return acc;
          },
          { dogs: [], cats: [] }
        )
      );
    }
  }, [teamPlayers, room]);

  console.log("TEAM PLAYERS", teamPlayers);
  console.log(playerLists, room);

  const isOnTeam =
    teamPlayers &&
    Object.values(teamPlayers).some((player) => player.id === user?.uid);

  if (!room || !round) return null;

  return (
    <div className="battleground">
      {puzzle.gameMode === "teams" || puzzle.gameMode === "speed" ? (
        <>
          <div
            className={`player audience cats ${
              round?.roundPlayer === "cats" ? "active" : "inactive"
            }`}
            style={
              winningTeam === "cats"
                ? {
                    background:
                      'url("https://media4.giphy.com/media/3rYxjPwF5i9mALN1UM/giphy.gif?cid=ecf05e47ybhq8bgzu2aqln5jf02xqzwgqoqvgi5fy5u7m056&rid=giphy.gif&ct=s")',
                  }
                : {}
            }
          >
            {round.catsVotes ? (
              <div className="votes">
                {Object.keys(round.catsVotes).map((key) =>
                  key.length > 1 ? (
                    <div className="vote">
                      <div
                        className={`${
                          key.length > 1 ? "phrase" : "letter"
                        } guess small`}
                      >
                        {key}
                      </div>{" "}
                      {round.catsVotes[key].total}
                    </div>
                  ) : null
                )}
              </div>
            ) : null}
            <div class="spriteGroup">
              {room &&
                playerLists.cats &&
                playerLists.cats.map((user) => {
                  const userVote = round.catsVotes
                    ? Object.keys(round.catsVotes).find((key) => {
                        return round.catsVotes[key]?.users.includes(user);
                      })
                    : false;
                  return (
                    <div style={{ position: "relative" }}>
                      <Avatar width={60} height={60} userId={user} />
                      {userVote && userVote.length === 1 && (
                        <div
                          className="guess small letter"
                          style={{
                            background: "white",
                            color: "black",
                            position: "absolute",
                            top: "36px",
                            right: "0",
                            border: "2px solid black",
                          }}
                        >
                          {userVote}
                        </div>
                      )}
                    </div>
                  );
                })}{" "}
            </div>
            <div className="displayName">Team Cats</div>
            {!isOnTeam ? (
              <div className="displayName">type "join cats"</div>
            ) : null}
          </div>
          <div
            className={`player audience dogs  ${
              round?.roundPlayer === "dogs" ? "active" : "inactive"
            }`}
            style={
              winningTeam === "dogs"
                ? {
                    background:
                      'url("https://media4.giphy.com/media/3rYxjPwF5i9mALN1UM/giphy.gif?cid=ecf05e47ybhq8bgzu2aqln5jf02xqzwgqoqvgi5fy5u7m056&rid=giphy.gif&ct=s")',
                  }
                : {}
            }
          >
            {round.dogsVotes ? (
              <div className="votes">
                {Object.keys(round.dogsVotes).map((key) =>
                  key.length > 1 ? (
                    <div className="vote">
                      <div
                        className={`${
                          key.length > 1 ? "phrase" : "letter"
                        } guess small`}
                      >
                        {key}
                      </div>{" "}
                      {round.votes[key].total}
                    </div>
                  ) : null
                )}
              </div>
            ) : null}
            <div className="spriteGroup">
              {room &&
                playerLists.dogs &&
                playerLists.dogs.map((user) => {
                  const userVote = round.dogsVotes
                    ? Object.keys(round.dogsVotes).find((key) => {
                        return round.dogsVotes[key]?.users?.includes(user);
                      })
                    : false;
                  return (
                    <div style={{ position: "relative" }}>
                      <Avatar width={60} height={60} userId={user} />
                      {userVote && userVote.length === 1 && (
                        <div
                          className="guess small letter"
                          style={{
                            background: "white",
                            color: "black",
                            position: "absolute",
                            top: "36px",
                            right: "0",
                            border: "2px solid black",
                          }}
                        >
                          {userVote}
                        </div>
                      )}
                    </div>
                  );
                })}{" "}
            </div>
            <div className="displayName">Team Dogs</div>
            {!isOnTeam ? (
              <div className="displayName">type "join dogs"</div>
            ) : null}
          </div>
        </>
      ) : (
        <>
          <div
            className={`player host  ${
              round?.roundPlayer === "host" ? "active" : "inactive"
            }`}
          >
            <div className="sprite">
              <Avatar width={120} height={120} userId={host?.id} />
            </div>
            <div className="displayName">
              {host?.displayName || "The Beast"}
            </div>
          </div>
          <div
            className={`player audience ${
              round?.roundPlayer === "audience" ? "active" : "inactive"
            }`}
          >
            <div class="spriteGroup">
              {room &&
                room.users &&
                room.users.map((user) => {
                  return <Avatar width={60} height={60} userId={user} />;
                })}
            </div>
            <div className="displayName">The Party</div>
          </div>
        </>
      )}
    </div>
  );
};

export default SpellraiserBattleground;
