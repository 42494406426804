import React, { Fragment } from "react";
import Room from "./Room";
import LiveChatBox from "./LiveChatBox";

import { useAuthState } from "react-firebase-hooks/auth";
import firebase, { authProviders } from "./firebase";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import styled from "styled-components";
import Pay from "./Pay";
import SpellraiserRoom from "./SpellraiserRoom";
import CrosswordRoom from "./CrosswordRoom";
import QuizRoom from "./QuizRoom";
import PuzzleUpload from "./PuzzleUpload";
import PaintRoom from "./PaintRoom";
const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const App = () => {
  return (
    <Router>
      <PageWrapper>
        <Switch>
          {/* <Route
            path="/pay"
            exact
            render={({ match }) => {
              return (
                <div>
                  <Pay
                    stripePublicKey={
                      "pk_test_teJmFmi5atCz4wpekr72jlpZ00NSLNhsnI"
                    }
                    handleResult={(result) => {
                      console.log("result", result);
                    }}
                  />
                </div>
              );
            }}
          /> */}
          <Route
            path="/room/:roomId"
            exact
            render={({ match }) => {
              return <Room roomId={match.params.roomId} />;
            }}
          />
          {/* <Route
            path="/club/:roomId"
            exact
            render={({ match }) => {
              return <PayRoom roomId={match.params.roomId} />;
            }}
          /> */}
          <Route
            path="/chat/:roomId"
            exact
            render={({ match }) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    overflow: "hidden",
                  }}
                >
                  <LiveChatBox roomId={match.params.roomId} />
                </div>
              );
            }}
          />
          <Route
            path="/spell/:roomId"
            exact
            render={({ match }) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    overflow: "hidden",
                  }}
                >
                  <SpellraiserRoom roomId={match.params.roomId} />
                </div>
              );
            }}
          />
          <Route
            path="/crossword/:roomId"
            exact
            render={({ match }) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    // overflow: "hidden",
                  }}
                >
                  <CrosswordRoom roomId={match.params.roomId} />
                </div>
              );
            }}
          />
          <Route
            path="/quiz/upload"
            exact
            render={({ match }) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    // overflow: "hidden",
                  }}
                >
                  <PuzzleUpload roomId={match.params.roomId} />
                </div>
              );
            }}
          />
          <Route
            path="/quiz/:roomId"
            exact
            render={({ match }) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    // overflow: "hidden",
                  }}
                >
                  <QuizRoom roomId={match.params.roomId} />
                </div>
              );
            }}
          />
          <Route
            path="/paint/:roomId"
            exact
            render={({ match }) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    // overflow: "hidden",
                  }}
                >
                  <PaintRoom roomId={match.params.roomId} />
                </div>
              );
            }}
          />
        </Switch>
      </PageWrapper>
    </Router>
  );
};

export default App;
