import React from "react";
import { useHarmonicIntervalFn, useUpdate } from "react-use";

const Countdown = (props) => {
  const update = useUpdate();
  useHarmonicIntervalFn(() => update(), 100);

  //   console.log("Countdown", props.endTime, new Date());

  if (!props.endTime) return null;

  return (
    <>
      {Math.max(
        Math.round((props.endTime.getTime() - new Date().getTime()) / 100),
        0
      ) / 10}
    </>
  );
};

export default Countdown;
