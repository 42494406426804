import React, { Fragment, useEffect, useRef } from "react";
import LiveChatBox from "./LiveChatBox";
import ReactPlayer from "react-player";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  useDocumentData,
  useCollectionData
} from "react-firebase-hooks/firestore";
import { useObjectVal } from "react-firebase-hooks/database";
import firebase, { authProviders } from "./firebase";
import styled from "styled-components";

const Presence = ({ roomId }) => {
  const [user, initializing, error] = useAuthState(firebase.auth());
  //   useEffect(() => {
  //     console.log("change", document.hidden);
  //     element.addEventListener("visibilitychange", eventListener);

  //     // Remove event listener on cleanup
  //     return () => {
  //       element.removeEventListener("visibilitychange", eventListener);
  //     };
  //   }, [document.hidden]);
  //   const [room, roomLoading, roomError] = useDocumentData(
  //     firebase.firestore().doc(`rooms/${roomId}`),
  //     { idField: "id" }
  //   );

  // useEventListener("visibilitychange", () => {
  //   console.log("hidden?", document.hidden);
  // });
  // Create a reference to this user's specific status node.
  // This is where we will store data about being online/offline.
  if (user) {
    let uid = user.uid;
    var userStatusDatabaseRef = firebase
      .database()
      .ref(`/status/${roomId}/${uid}`);

    var isOfflineForDatabase = {
      state: "offline",
      last_changed: firebase.database.ServerValue.TIMESTAMP
    };

    var isOnlineForDatabase = {
      state: "online",
      last_changed: firebase.database.ServerValue.TIMESTAMP
    };

    // Create a reference to the special '.info/connected' path in
    // Realtime Database. This path returns `true` when connected
    // and `false` when disconnected.
    firebase
      .database()
      .ref(".info/connected")
      .on("value", function(snapshot) {
        // If we're not currently connected, don't do anything.
        if (snapshot.val() == false) {
          return;
        }

        // If we are currently connected, then use the 'onDisconnect()'
        // method to add a set which will only trigger once this
        // client has disconnected by closing the app,
        // losing internet, or any other means.
        userStatusDatabaseRef
          .onDisconnect()
          .set(isOfflineForDatabase)
          .then(function() {
            // The promise returned from .onDisconnect().set() will
            // resolve as soon as the server acknowledges the onDisconnect()
            // request, NOT once we've actually disconnected:
            // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

            // We can now safely set ourselves as 'online' knowing that the
            // server will mark us as offline once we lose connection.
            userStatusDatabaseRef.set(isOnlineForDatabase);
          });
      });
  }

  return null;
};

export default Presence;
