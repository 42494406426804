import React, { useCallback, useEffect, useState } from "react";
import { CSVReader, usePapaParse } from "react-papaparse";
import firebase, { authProviders } from "./firebase";

const PuzzleUpload = (props) => {
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const [results, setResults] = useState(null);
  const { readString } = usePapaParse();
  const processAndUpload = useCallback(
    (e) => {
      readString(file, {
        worker: true,
        complete: (results) => {
          setResults(results);
        },
      });
    },
    [file, readString, setResults]
  );

  useEffect(() => {
    processAndUpload(file);
  }, [processAndUpload, file]);

  const addToFirestore = () => {
    firebase
      .firestore()
      .collection("quizPuzzles")
      .add({
        name,
        allAnswers: results.data.map((row) => row[0].toUpperCase().trim()),
        clues: results.data.reduce((acc, row) => {
          acc[row[0].toUpperCase().trim()] = {
            answer: row[0],
            clue: row[1] || null,
          };
          return acc;
        }, {}),
        addedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
  };

  return (
    <div>
      <p>
        <input
          type="file"
          onChange={(e) => {
            console.log("SET FILE", e.target.files[0]);
            setFile(e.target.files[0]);
          }}
        />
      </p>
      <div>{results && JSON.stringify(results)}</div>
      <input
        type="text"
        placeholder="Name"
        onChange={(e) => setName(e.target.value)}
      />
      <button onClick={addToFirestore}>Add</button>
    </div>
  );
};

export default PuzzleUpload;
