import React from "react";
import { useHarmonicIntervalFn, useUpdate } from "react-use";

const Countup = (props) => {
  const update = useUpdate();
  useHarmonicIntervalFn(() => update(), 1000);

  //   console.log("Countdown", props.endTime, new Date());

  if (!props.startTime) return null;
  //   console.log(new Date().getTime(), props.startTime.getTime());
  const totalSeconds =
    (new Date().getTime() - props.startTime.getTime()) / 1000;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.round(totalSeconds - minutes * 60);
  return (
    <>
      {minutes}:{String(seconds).padStart(2, "0")}
    </>
  );
};

export default Countup;
