import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import Crossword, {
  CrosswordGrid,
  CrosswordProvider,
  ThemeProvider,
} from "@jaredreisinger/react-crossword";
import Avatar from "./Avatar";

const CLUE_SWAP_SPEED = 12000;

const CrosswordWrapper = styled.div`
  pointer-events: none;
  .guessed {
    text-decoration: line-through;
    color: #aaa;
  }
`;

const TeamEmoji = (props) => {
  const { team, style } = props;
  return (
    <div style={{ lineHeight: 1, ...style }}>
      {team === "cats" ? "🐱" : ""}
      {team === "dogs" ? "🐶" : ""}
    </div>
  );
};

const CrosswordPuzzle = (props) => {
  const {
    puzzleData,
    guesses,
    allGuesses,
    userGuesses,
    gameMode,
    playerLists,
  } = props;
  console.log("props", props);
  const puzzEl = useRef(null);
  const [puzzleLoaded, setPuzzleLoaded] = useState(false);
  const [crosswordCorrect, setCrosswordCorrect] = useState(false);
  const [showClues, setShowClues] = useState("across");
  const [scoreboard, setScoreboard] = useState({ players: {}, teams: {} });

  console.log("moo", gameMode, playerLists, scoreboard);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setShowClues(showClues === "across" ? "down" : "across");
    }, CLUE_SWAP_SPEED);

    return () => {
      clearInterval(intervalId);
    };
  }, [setShowClues, showClues]);

  const fillGuesses = useCallback(() => {
    // puzzEl.current.reset();
    // puzzEl.current.setGuess(0, 0, "A");
    guesses.forEach((guess, i) => {
      if (guess.dir === "across") {
        for (let i = 0; i < guess.answer.length; i++) {
          puzzEl.current.setGuess(
            guess.row,
            guess.col + i,
            guess.answer.charAt(i)
          );
        }
      }
      if (guess.dir === "down") {
        for (let i = 0; i < guess.answer.length; i++) {
          puzzEl.current.setGuess(
            guess.row + i,
            guess.col,
            guess.answer.charAt(i)
          );
        }
      }
    });

    const scoreboard = Object.values(userGuesses).reduce(
      (acc, guess) => {
        if (acc.players[guess[0].userId]) {
          acc.players[guess[0].userId] = acc.players[guess[0].userId] + 1;
        } else {
          acc.players[guess[0].userId] = 1;
        }
        if (gameMode === "teams") {
          if (acc.teams[guess[0].team]) {
            acc.teams[guess[0].team] = acc.teams[guess[0].team] + 1;
          } else {
            acc.teams[guess[0].team] = 1;
          }
        }
        return acc;
      },
      { players: {}, teams: {} }
    );

    setScoreboard(scoreboard);
  }, [puzzEl, guesses, userGuesses, setScoreboard, gameMode]);

  useEffect(() => {
    if (puzzEl.current && puzzEl.current.setGuess) {
      setTimeout(fillGuesses, 50);
    }
  }, [guesses, puzzEl, puzzleLoaded, fillGuesses]);

  //   useEffect(() => {
  //     if (puzzEl.current) {
  //       puzzEl.current.onLoadedCorrect(setPuzzleLoaded);
  //     }
  //   }, [puzzEl]);

  console.log("scoreboard", scoreboard);
  return (
    <>
      <CrosswordWrapper
        style={{
          width: "100%",
          minWidth: "800px",
          display: "flex",
          position: "relative",
        }}
      >
        {crosswordCorrect && (
          <div
            style={{
              position: "absolute",
              top: "-50px",
              left: "50%",
              transform: "translate(-50%)",
              background: "gold",
              color: "black",
              fontWeight: "bold",
              fontSize: 30,
              zIndex: 1000,
              padding: 10,
              borderRadius: 10,
            }}
          >
            Puzzle Complete!
          </div>
        )}
        <ThemeProvider
          theme={{
            numberColor: "#000",
          }}
        >
          <CrosswordProvider
            useStorage={false}
            data={puzzleData}
            ref={puzzEl}
            onCrosswordCorrect={setCrosswordCorrect}
            //   onLoadedCorrect={setPuzzleLoaded}
            // onCellChange={console.log}
          >
            <CrosswordGrid />
          </CrosswordProvider>
        </ThemeProvider>
        <ol
          id="across"
          style={{
            columnCount: 2,
            width: "33%",
            display: true ? "block" : "none",
          }}
        >
          <div style={{ marginBottom: 8, fontSize: 13 }}>
            <strong>Across</strong>
          </div>
          {Object.keys(puzzleData.across).map((key) => {
            const guessed = allGuesses?.includes(puzzleData.across[key].answer);

            return (
              <li
                value={key}
                style={{ paddingBottom: 8, fontSize: 13, position: "relative" }}
                key={`across${key}`}
                className={`clue ${guessed ? "guessed" : ""}`}
              >
                {guessed ? (
                  <div
                    style={{
                      position: "absolute",
                      left: -30,
                      top: -8,
                      fontSize: 24,
                    }}
                  >
                    {gameMode === "teams" ? (
                      <TeamEmoji
                        style={{ marginLeft: 6 }}
                        team={
                          userGuesses[puzzleData.across[key].answer][0].team
                        }
                      />
                    ) : (
                      <Avatar
                        width={30}
                        height={30}
                        userId={
                          userGuesses[puzzleData.across[key].answer][0].userId
                        }
                      />
                    )}
                  </div>
                ) : null}
                {puzzleData.across[key].clue}
              </li>
            );
          })}
        </ol>
        <ol
          id="down"
          style={{
            columnCount: 2,
            width: "33%",
            display: true ? "block" : "none",
          }}
        >
          <div style={{ marginBottom: 8, fontSize: 13 }}>
            <strong>Down</strong>
          </div>
          {Object.keys(puzzleData.down).map((key) => {
            const guessed = allGuesses?.includes(puzzleData.down[key].answer);
            return (
              <li
                value={key}
                key={`down${key}`}
                style={{ paddingBottom: 8, fontSize: 13, position: "relative" }}
                className={`clue ${guessed ? "guessed" : ""}`}
              >
                {guessed ? (
                  <div
                    style={{
                      position: "absolute",
                      left: -30,
                      top: -8,
                      fontSize: 24,
                    }}
                  >
                    {gameMode === "teams" ? (
                      <TeamEmoji
                        style={{ marginLeft: 6 }}
                        team={userGuesses[puzzleData.down[key].answer][0].team}
                      />
                    ) : (
                      <Avatar
                        width={30}
                        height={30}
                        userId={
                          userGuesses[puzzleData.down[key].answer][0].userId
                        }
                      />
                    )}
                  </div>
                ) : null}
                {puzzleData.down[key].clue}
              </li>
            );
          })}
        </ol>
      </CrosswordWrapper>
      {gameMode === "teams" ? (
        <div style={{ display: "flex" }}>
          {Object.keys(playerLists).map((team, i) => (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 20,
                  fontSize: 36,
                }}
              >
                <TeamEmoji team={team} />
                {scoreboard.teams[team] || "0"}
              </div>
              {playerLists[team].map((player) => (
                <Avatar userId={player} width={30} height={30} />
              ))}
            </div>
          ))}
        </div>
      ) : (
        <div style={{ display: "flex" }}>
          {Object.keys(scoreboard.players).map((userId, i) => (
            <div
              style={{ display: "flex", alignItems: "center", marginRight: 20 }}
            >
              <Avatar width={50} height={50} userId={userId} />
              {scoreboard.players[userId]}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CrosswordPuzzle;
